.s-contact-header
  position: relative
  padding-top: 32px
  padding-bottom: 32px
  min-height: 460px
  @media only screen and (max-width: 1100px)
    min-height: 412px
  @media only screen and (max-width: 600px)
    min-height: 396px

  &__content
    display: flex
    align-items center
    justify-content center
    width: 100%

  &__bg-wrap
    position: absolute
    top: -24px
    left: 50%
    transform translateX(-50%)
    max-width 100%
    height auto
    display: flex
    justify-content center
    img
      width: 100%
      min-width 968px


  &__text-wrap
    position: relative
    z-index: 2

  &__pretitle
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #19063D;
    margin-bottom: 12px
    @media only screen and (max-width: 1100px)
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 8px

  &__title
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #19063D;
    margin-bottom: 24px
    @media only screen and (max-width: 1100px)
      font-size: 36px;
      line-height: 45px;
      margin-bottom: 18px

  &__text
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #514468;
    @media only screen and (max-width: 1100px)
      font-size: 15px;
      line-height: 22px;

