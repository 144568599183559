.s-contact-us
  padding: 0

  &__content
    display: flex
    @media only screen and (max-width: 760px)
      flex-wrap wrap

  &__block
    width: 50%
    @media only screen and (max-width: 760px)
      width: 100%

  &__map
    width: 100%
    height: 100%
    @media only screen and (max-width: 760px)
      min-height 655px

  &__visit-us
    position: relative
    padding: 112px 96px
    background: radial-gradient(89.24% 304.82% at 92.36% 150.75%, #3B2957 20.19%, #1A0643 100%)
    @media only screen and (max-width: 1100px)
      padding: 72px 24px
    @media only screen and (max-width: 600px)
      padding: 72px 16px

  &__form-wrap
    background: #ffffff
    padding: 72px 96px
    @media only screen and (max-width: 1100px)
      padding: 54px 24px
    @media only screen and (max-width: 600px)
      padding: 54px 16px

  .s-contact-us-visit-us

    &__bg
      position: absolute
      top: 50%
      left: 50%
      transform translate(-50%,-50%)
      width: 100%
      height: 100%
      object-fit cover

    &__pretitle
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #CBBFE3;
      margin-bottom: 12px
      @media only screen and (max-width: 1100px)
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 8px

    &__title
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      margin-bottom: 32px
      @media only screen and (max-width: 1100px)
        font-size: 27px;
        line-height: 33px;
        margin-bottom: 24px

    &__city
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      margin-bottom: 8px
      @media only screen and (max-width: 1100px)
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 6px

    &__adress
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #CBBFE3;
      @media only screen and (max-width: 1100px)
        font-size: 12px;
        line-height: 18px

    &__link-wrap
      display: flex

    &__link
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #CBBFE3;
      text-decoration: none
      @media only screen and (max-width: 1100px)
        font-size: 12px;
        line-height: 18px

  .s-contact-us-form

    &__title
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: #19063D;
      margin-bottom: 20px
      @media only screen and (max-width: 1100px)
        font-size: 27px;
        line-height: 33px;
        margin-bottom: 16px

    &__text
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #514468;
      @media only screen and (max-width: 1100px)
        font-size: 15px;
        line-height: 22px;
      a
        font-size inherit
        line-height inherit
        font-weight: inherit
        color #713DDD
        text-decoration: none


//contact page wp
.s-contact-header
  padding: 0!important
  .s-contact-header__text-wrap
    p
      margin-top: 12px;
      font-size: 20px;
      line-height: 30px;
.s-contact-us
  padding: 0!important