html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
strong,
abbr,
acronym,
blockquote,
q,
cite,
ins,
del,
dfn,
a,
div,
span,
pre,
hr,
address,
br,
b,
i,
sub,
big,
small,
tt,
table,
tr,
caption,
thead,
tbody,
tfoot,
col,
colgroup,
form,
input,
label,
textarea,
button,
fieldset,
legend,
select,
option,
ul,
ol,
li,
dl,
dt,
dd,
code,
var,
samp,
img,
object,
param,
map,
area {
  padding: 0;
  margin: 0;
  border: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
header,
footer,
section,
aside,
figure,
figcaption,
input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type=button],
input[type=submit],
button {
  cursor: pointer;
}
button {
  border: 0;
}
:focus {
  outline: none;
}
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
  outline: none;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
input,
textarea {
  resize: none;
  user-select: text;
}
::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}
a {
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.owl-item {
  transform: translateZ(0);
}
img {
  display: block;
}
button {
  background: transparent;
}
input,
textarea {
  font-family: 'Alexandria', sans-serif;
  font-weight: 400;
  -webkit-appearance: none;
  border-radius: 0;
  color: #4d4d4d;
}
html,
body {
  color: #4d4d4d;
  height: 100%;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  font-family: 'Alexandria', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
* {
  margin: 0;
  padding: 0;
}
header,
footer,
section {
  max-width: 100vw;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  max-width: 100vw;
  overflow-x: hidden;
}
.b-wrapper {
  width: 100%;
  display: table;
  height: 100%;
  padding-top: 120px;
  max-width: 100vw;
  overflow-x: hidden;
}
@media only screen and (max-width: 976px) {
  .b-wrapper {
    padding-top: 97px;
  }
}
@media only screen and (max-width: 767px) {
  .b-wrapper {
    padding-top: 72px;
  }
}
.content {
  display: table-row;
  width: 100%;
  height: 100%;
}
span {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
}
.section {
  width: 100%;
}
.hidden {
  display: none !important;
}
.container {
  max-width: 1272px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.row {
  margin-bottom: 0;
}
.col {
  padding: 0 15px;
}
@media only screen and (max-width: 600px) {
  .col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.dropdown-content {
  top: 40px !important;
  background: #fff;
  box-shadow: 0px 32px 38px -4px rgba(0,0,0,0.07);
  border-radius: 20px;
  min-width: 164px;
  padding: 8px !important;
}
.dropdown-content li {
  min-height: inherit;
  background: transparent !important;
}
.dropdown-content li>a {
  padding: 6px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #19063d;
  border-radius: 8px;
  transition: all 0.3s ease;
}
.dropdown-content li>a:hover {
  background: #f9f5ff;
}
.btn--purple {
  background: #713ddd;
  border: 1px solid #713ddd;
  box-shadow: 0px 16px 38px -4px rgba(0,0,0,0.16);
  border-radius: 42px;
  padding: 10px 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}
.bg-text {
  color: #fff;
  position: relative;
  display: inline-flex;
  margin-left: 8px;
}
.bg-text:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../img/text-bg.png") center no-repeat;
  background-size: cover;
  z-index: -1;
}
.collapsible {
  border: none;
  box-shadow: none;
}
.collapsible li {
  padding-top: 24px;
  padding-bottom: 36px;
  border-bottom: 1px solid #eaecf0;
}
.collapsible li:last-child {
  border-bottom: none;
}
.collapsible li.active .collapsible-header .collapsible-header__plus:before {
  display: none !important;
}
.collapsible li .collapsible-body,
.collapsible li .collapsible-header {
  border: none;
  position: relative;
}
.collapsible li .collapsible-body {
  padding: 8px 46px 0 0;
}
@media only screen and (max-width: 600px) {
  .collapsible li .collapsible-body {
    padding: 8px 30px 0 0;
  }
}
.collapsible li .collapsible-body__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #514468;
}
.collapsible li .collapsible-header {
  padding: 0 46px 0 0;
}
@media only screen and (max-width: 600px) {
  .collapsible li .collapsible-header {
    padding: 0 30px 0 0;
  }
}
.collapsible li .collapsible-header__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #19063d;
}
.collapsible li .collapsible-header__plus {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #ff8057;
  border-radius: 50%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.collapsible li .collapsible-header__plus:after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ff8057;
}
.collapsible li .collapsible-header__plus:before {
  content: '';
  display: block;
  position: absolute;
  width: 2px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ff8057;
}
.top-menu {
  height: auto;
  line-height: inherit;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 38px 112px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  box-shadow: none;
  z-index: 100;
}
@media only screen and (max-width: 1400px) {
  .top-menu {
    padding: 28px 34px;
  }
}
@media only screen and (max-width: 600px) {
  .top-menu {
    padding: 16px 20px;
  }
}
.top-menu.not-top {
  background-color: #fff;
}
.top-menu .brand-logo {
  position: static;
  transform: none !important;
}
.top-menu .anqors-wrap,
.top-menu .nav-wrapper {
  display: flex;
  align-items: center;
}
.top-menu .anqors-wrap {
  margin-left: 56px;
  margin-bottom: 0;
}
.top-menu .anqors-wrap .menu-item a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #19063d;
  transition: all 0.3s ease;
  padding: 0;
  margin: 0 16px;
  background: transparent;
}
.top-menu .anqors-wrap .menu-item a:hover {
  color: #713ddd;
}
.top-menu .anqors-wrap .menu-item.current-menu-item a {
  color: #713ddd;
}
.top-menu .anqors-wrap .menu-item.menu-item-has-children {
  padding-right: 23px;
  position: relative;
}
.top-menu .anqors-wrap .menu-item.menu-item-has-children.active {
  background: none;
}
.top-menu .anqors-wrap .menu-item.menu-item-has-children:after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  background: url("../img/dropdown.svg") center no-repeat;
  background-size: cover;
}
.top-menu .anqors-wrap .menu-item .sub-menu {
  position: absolute;
  top: 40px !important;
  background: #fff;
  box-shadow: 0px 32px 38px -4px rgba(0,0,0,0.07);
  border-radius: 20px;
  min-width: 164px;
  padding: 8px !important;
  display: none;
}
.top-menu .anqors-wrap .menu-item .sub-menu li {
  min-height: inherit;
  background: transparent !important;
  width: 100%;
}
.top-menu .anqors-wrap .menu-item .sub-menu li.current-menu-item a {
  background: #f9f5ff;
}
.top-menu .anqors-wrap .menu-item .sub-menu li>a {
  padding: 6px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #19063d;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin: 0;
}
.top-menu .anqors-wrap .menu-item .sub-menu li>a:hover {
  background: #f9f5ff;
}
.auth-wrapper {
  display: flex;
  align-items: center;
}
.auth-wrapper .dropdown-content {
  top: 90px !important;
}
.auth-wrapper .top-menu__login {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #713ddd;
  padding: 10px 18px;
  margin: 0 8px;
}
.auth-wrapper .top-menu__signup {
  margin: 0 8px;
}
.auth-wrapper .dropdown-content--lang {
  top: 60px !important;
}
.auth-wrapper .dropdown-content--lang a {
  padding: 10px 12px;
  background: transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #514468;
}
.auth-wrapper .dropdown-content--lang a:hover {
  background: #f9f5ff;
}
.auth-wrapper .dropdown-content--lang a .top-menu__lang-flag,
.auth-wrapper .dropdown-content--lang a img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}
.auth-wrapper .top-menu__lang {
  padding: 10px 12px;
  background: #f9f5ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #514468;
}
.auth-wrapper .top-menu__lang .top-menu__lang-flag {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}
.auth-wrapper .top-menu__lang .top-menu__lang-caret {
  margin-left: 12px;
}
@media only screen and (max-width: 992px) {
  .desctop-menu {
    display: none;
  }
}
.mobile-menu {
  display: none;
}
@media only screen and (max-width: 992px) {
  .mobile-menu {
    display: block;
  }
}
.mobile-menu .nav-wrapper {
  width: 100%;
  justify-content: space-between;
}
.mobile-menu .sidenav-trigger {
  height: auto;
  margin: 0;
}
.sidenav {
  background-color: #fff;
  width: 380px;
  z-index: 10000;
  padding: 20px !important;
}
@media only screen and (max-width: 600px) {
  .sidenav {
    width: 100%;
  }
}
.sidenav .sidenav-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}
.sidenav .sidenav-header .sidenav-cross {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidenav .sidenav-header .sidenav-cross img {
  height: 14px;
  width: 14px;
}
.sidenav .sidenav-content {
  padding-top: 16px;
  padding-bottom: 16px;
}
.sidenav .sidenav-content .top-menu__login {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #713ddd;
  padding: 10px 18px;
}
.sidenav .sidenav-content .top-menu__link-wrap {
  margin: 24px 0;
  display: flex;
}
.sidenav .sidenav-content .top-menu__link-wrap--btns {
  margin: 12px 0;
}
.sidenav .sidenav-content .top-menu__link {
  font-weight: 800;
  font-size: 27px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #19063d;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item {
  margin: 24px 0;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item a {
  font-weight: 800;
  font-size: 27px;
  line-height: 32px;
  height: inherit;
  letter-spacing: -0.02em;
  color: #19063d;
  padding: 0;
  width: fit-content;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.current-menu-item a {
  color: #713ddd;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.menu-item-has-children {
  padding-right: 23px;
  position: relative;
  transition: all 0.3s ease;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.menu-item-has-children:after {
  content: '';
  display: block;
  width: 16px;
  height: 10px;
  position: absolute;
  right: 14px;
  top: 12px;
  transform: scaleY(-1);
  background: url("../img/panel-dropdown-arrow.svg") center no-repeat;
  background-size: cover;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.menu-item-has-children.active-menu a {
  color: #713ddd;
  background: none;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.menu-item-has-children.active-menu:after {
  transform: scaleY(1);
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.menu-item-has-children .sub-menu {
  display: none;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.menu-item-has-children .sub-menu li {
  width: 100%;
  margin: 12px 0;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.menu-item-has-children .sub-menu li>a {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #19063d;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.active {
  background: transparent !important;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.active .collapsible-header .collapsible-header__title {
  color: #713ddd !important;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item.active .collapsible-header .collapsible-header__arrow-wrap {
  transform: rotate(0);
}
.sidenav .sidenav-content .top-menu__collapse .menu-item .collapsible-header {
  padding: 0;
  display: flex;
  align-items: center;
  background: transparent !important;
  margin-bottom: 12px;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item .collapsible-header .collapsible-header__arrow-wrap {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item .collapsible-header .collapsible-header__title {
  font-weight: 800;
  font-size: 27px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-right: 16px;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item .collapsible-body {
  padding: 0;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item .collapsible-body .collapsible-body__link-wrap {
  margin: 12px 0;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item .collapsible-body .collapsible-body__link {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #19063d;
}
.sidenav .sidenav-content .top-menu__collapse .menu-item .collapsible-body .collapsible-body__link:hover {
  color: #713ddd;
}
.rtl .top-menu .anqors-wrap .menu-item.menu-item-has-children {
  padding-left: 23px;
  padding-right: inherit;
}
.rtl .top-menu .anqors-wrap .menu-item.menu-item-has-children:after {
  content: none;
}
.rtl .top-menu .anqors-wrap .menu-item.menu-item-has-children:before {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  background: url("../img/dropdown.svg") center no-repeat;
  background-size: cover;
}
.rtl .sidenav .sidenav-content .top-menu__collapse .menu-item.menu-item-has-children {
  padding-left: 23px;
  padding-right: inherit;
}
.rtl .sidenav .sidenav-content .top-menu__collapse .menu-item.menu-item-has-children:after {
  right: auto;
  left: 14px;
}
.auth-wrapper .top-menu__lang .top-menu__lang-caret {
  margin-right: 12px;
  margin-left: inherit;
}
.s-header {
  position: relative;
  z-index: 2;
  padding-top: 100px;
  height: 800px;
}
@media only screen and (max-width: 1100px) {
  .s-header {
    height: 458px;
    padding-top: 52px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header {
    height: auto;
    padding-bottom: 380px;
  }
}
.s-header__bg-img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.s-header__bg-img--t,
.s-header__bg-img--m {
  display: none;
}
@media only screen and (max-width: 1100px) {
  .s-header__bg-img--t {
    display: block;
    margin-left: 74px;
  }
  .s-header__bg-img--d,
  .s-header__bg-img--m {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__bg-img {
    bottom: 0;
    transform: translateX(-50%);
    top: auto;
  }
  .s-header__bg-img--m {
    display: block;
  }
  .s-header__bg-img--d,
  .s-header__bg-img--t {
    display: none;
  }
}
.s-header__content {
  position: relative;
  z-index: 2;
}
.s-header__text-wrap {
  max-width: 576px;
  position: relative;
}
@media only screen and (max-width: 1100px) {
  .s-header__text-wrap {
    max-width: 360px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__text-wrap {
    max-width: inherit;
  }
}
.s-header__arrow-right {
  position: absolute;
  top: 20px;
  right: -20px;
}
@media only screen and (max-width: 1100px) {
  .s-header__arrow-right {
    position: absolute;
    top: -20px;
    right: -90px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__arrow-right {
    display: none;
  }
}
.s-header__arrow-left {
  position: absolute;
  bottom: -100px;
  right: 100px;
}
@media only screen and (max-width: 1100px) {
  .s-header__arrow-left {
    bottom: -70px;
    right: -44px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__arrow-left {
    display: none;
  }
}
.s-header__arrow-heart {
  position: absolute;
  top: 110px;
  right: 40px;
}
@media only screen and (max-width: 1100px) {
  .s-header__arrow-heart {
    top: 80px;
    right: -60px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__arrow-heart {
    right: 10px;
    height: 46px;
  }
}
.s-header__ptetitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #19063d;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1100px) {
  .s-header__ptetitle {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;
  }
}
.s-header__title {
  font-weight: 800;
  font-size: 72px;
  line-height: 90px;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1100px) {
  .s-header__title {
    font-size: 54px;
    line-height: 68px;
    letter-spacing: -0.02em;
    margin-bottom: 18px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__title {
    font-size: 44px;
    line-height: 44px;
    position: relative;
    z-index: 3;
  }
}
.s-header__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .s-header__text {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
}
.s-header__btn-wrap {
  display: flex;
  align-items: center;
  margin-top: 48px;
}
@media only screen and (max-width: 1100px) {
  .s-header__btn-wrap {
    margin-top: 36px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__btn-wrap {
    flex-wrap: wrap;
  }
}
.s-header__started-btn {
  display: flex;
  align-items: center;
  padding: 16px 28px;
  background: #713ddd;
  border: 1px solid #713ddd;
  box-shadow: 0px 24px 38px -4px rgba(0,0,0,0.16);
  border-radius: 42px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}
@media only screen and (max-width: 1100px) {
  .s-header__started-btn {
    padding: 12px 20px;
    font-size: 14px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__started-btn {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.s-header__started-btn .more-wrap {
  margin-left: 12px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff8057;
  box-shadow: 0px 3px 7px rgba(0,0,0,0.25);
}
@media only screen and (max-width: 1100px) {
  .s-header__started-btn .more-wrap {
    width: 20px;
    height: 20px;
  }
}
.s-header__work-btn {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #19063d;
  margin-left: 28px;
}
@media only screen and (max-width: 1100px) {
  .s-header__work-btn {
    margin-left: 20px;
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__work-btn {
    margin-left: 0;
    margin-bottom: 20px;
  }
}
.s-header__work-btn .play-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 16px 38px -4px rgba(0,0,0,0.16);
  margin-right: 16px;
}
@media only screen and (max-width: 1100px) {
  .s-header__work-btn .play-wrap {
    width: 45px;
    height: 45px;
    margin-right: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-about {
    padding-bottom: 64px;
  }
}
.s-header.s-header-about .s-header__title {
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-about .s-header__title {
    font-size: 36px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-about .s-header__title {
    font-size: 44px;
    line-height: 56px;
  }
}
.s-header.s-header-about .s-header__bg-img {
  left: 0;
  transform: translateY(-50%);
  margin-left: 0 !important;
}
.s-header.s-header-about .s-header__bg-img--t {
  left: -164px;
}
.s-header.s-header-about .s-header__bg-img--m {
  position: static;
  margin-bottom: 0;
  max-width: 100%;
  transform: none;
  margin-left: auto !important;
  margin-right: auto !important;
}
.s-header.s-header-about .s-header__text-wrap {
  margin-left: auto;
}
.s-header.s-header-about .s-header__blocks-wrap {
  display: flex;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: 48px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-about .s-header__blocks-wrap {
    margin-top: 32px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-about .s-header__blocks-wrap {
    margin-top: 16px;
  }
}
.s-header.s-header-about .s-header__block {
  width: calc(100% / 2 - 32px);
  margin: 16px;
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-about .s-header__block {
    width: calc(100% / 1 - 32px);
  }
}
.s-header.s-header-about .s-header__block-title {
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -0.02em;
  color: #713ddd;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-about .s-header__block-title {
    font-size: 45px;
    line-height: 54px;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-about .s-header__block-title {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 12px;
  }
}
.s-header.s-header-about .s-header__block-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #19063d;
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-about .s-header__block-text {
    font-size: 14px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-about .s-header__block-text {
    font-size: 18px;
    line-height: 28px;
  }
}
.s-header.s-header-redeem .container {
  position: relative;
}
.s-header.s-header-redeem .s-header__bg-img {
  left: auto;
  right: 0;
  transform: translateY(-50%);
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-redeem .s-header__bg-img {
    width: 600px;
    right: -150px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-redeem .s-header__bg-img {
    width: 100%;
    max-width: 100%;
    position: static;
    transform: none;
  }
}
.s-header.s-header-redeem .s-header__title {
  max-width: 420px;
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-redeem .s-header__title {
    max-width: 320px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-redeem .s-header__title {
    max-width: inherit;
  }
}
.s-header.s-header-redeem .s-header__text-wrap {
  margin-left: 0;
  margin-right: auto;
}
.s-header.s-header-redeem .s-header__block-title {
  font-size: 80px;
  line-height: 72px;
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-redeem .s-header__block-title {
    font-size: 60px;
    line-height: 54px;
  }
}
@media only screen and (max-width: 600px) {
  .s-header.s-header-redeem .s-header__block-title {
    font-size: 60px;
    line-height: 54px;
  }
}
.s-header.s-header-redeem .s-header__block {
  position: relative;
  width: 130px;
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-redeem .s-header__block {
    width: 98px;
  }
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-redeem .s-header__block .s-header__block-text {
    font-size: 14px;
    line-height: 21px;
  }
}
.s-header.s-header-redeem .s-header__block .s-header__block-icon {
  position: absolute;
  padding: 8px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 12px 28.5px -3px rgba(0,0,0,0.16);
  right: -62px;
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1100px) {
  .s-header.s-header-redeem .s-header__block .s-header__block-icon {
    width: 34px;
    height: 34px;
    right: -44px;
    top: 8px;
  }
}
.s-header.s-header-redeem .s-header__block .s-header__block-icon img {
  max-width: 100%;
  max-height: 100%;
}
.s-footer {
  position: relative;
  background: radial-gradient(89.24% 304.82% at 92.36% 150.75%, #3b2957 20.19%, #1a0643 100%);
  padding-top: 64px;
  padding-bottom: 48px;
}
@media only screen and (max-width: 992px) {
  .s-footer {
    padding-top: 52px;
    padding-bottom: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .s-footer {
    padding-top: 64px;
    padding-bottom: 48px;
  }
}
.s-footer__bottom {
  padding-top: 32px;
  border-top: 1px solid #554671;
  display: flex;
  justify-content: space-between;
}
.s-footer__copyright {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  padding-right: 24px;
}
@media only screen and (max-width: 992px) {
  .s-footer__copyright {
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .s-footer__copyright {
    font-size: 14px;
    line-height: 24px;
  }
}
.s-footer__socilals-wrap {
  display: flex;
  align-items: center;
  gap: 24px;
}
@media only screen and (max-width: 992px) {
  .s-footer__socilals-wrap {
    gap: 18px;
  }
}
@media only screen and (max-width: 992px) {
  .s-footer__socilal {
    width: 18px;
  }
  .s-footer__socilal img {
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .s-footer__socilal {
    width: auto;
  }
}
.s-footer__content {
  display: flex;
  align-items: flex-start;
  padding-bottom: 58px;
}
@media only screen and (max-width: 992px) {
  .s-footer__content {
    flex-wrap: wrap;
  }
}
.s-footer__logo-wrap {
  width: 340px;
}
@media only screen and (max-width: 1100px) {
  .s-footer__logo-wrap {
    width: 300px;
  }
}
@media only screen and (max-width: 992px) {
  .s-footer__logo-wrap {
    width: 100%;
  }
}
.s-footer__logo {
  margin-bottom: 28px;
}
@media only screen and (max-width: 992px) {
  .s-footer__logo {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .s-footer__logo {
    margin-bottom: 28px;
  }
}
.s-footer__logo-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #eaecf0;
}
@media only screen and (max-width: 992px) {
  .s-footer__logo-text {
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .s-footer__logo-text {
    font-size: 16px;
    line-height: 24px;
  }
}
.s-footer__links-wrap {
  width: calc(100% - 340px + 32px);
  display: flex;
  align-items: flex-start;
  margin-left: -16px;
}
@media only screen and (max-width: 1100px) {
  .s-footer__links-wrap {
    width: calc(100% - 300px + 32px);
  }
}
@media only screen and (max-width: 992px) {
  .s-footer__links-wrap {
    width: calc(100% + 32px);
    margin-top: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .s-footer__links-wrap {
    flex-wrap: wrap;
    margin-top: 0;
  }
}
.s-footer__link-col {
  width: calc(100% / 5 - 32px);
  margin-left: 16px;
  margin-right: 16px;
}
@media only screen and (max-width: 767px) {
  .s-footer__link-col {
    width: calc(100% / 2 - 32px);
    margin-top: 48px;
  }
}
.s-footer__link-col-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 10px;
}
@media only screen and (max-width: 992px) {
  .s-footer__link-col-title {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 7px;
  }
}
@media only screen and (max-width: 767px) {
  .s-footer__link-col-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}
.s-footer__link-wrap {
  display: flex;
}
.s-footer__link {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  opacity: 0.7;
  transition: all 0.3s ease;
  margin-top: 6px;
  margin-bottom: 6px;
}
@media only screen and (max-width: 992px) {
  .s-footer__link {
    margin-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .s-footer__link {
    font-size: 14px;
    line-height: 24px;
  }
}
.s-footer__link:hover {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .s-footer__links-wrap {
    text-align: left;
  }
}
@media only screen and (max-width: 767px) {
  .s-footer__links-wrap {
    flex-wrap: wrap;
  }
}
.s-footer__links-wrap > li.menu-item {
  width: calc(100% / 5);
  margin-left: 16px;
  margin-right: 16px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .s-footer__links-wrap > li.menu-item {
    width: calc(100% / 2 - 32px);
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 48px;
  }
}
.s-footer__links-wrap > li.menu-item a {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 10px;
}
.s-footer__links-wrap > li.menu-item ul {
  margin: 0;
}
.s-footer__links-wrap > li.menu-item ul li.menu-item {
  margin-top: 7px;
  margin-bottom: 7px;
}
.s-footer__links-wrap > li.menu-item ul li.menu-item a {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  opacity: 0.7;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.s-footer__links-wrap > li.menu-item ul li.menu-item a:hover {
  opacity: 1;
}
.site-primary-footer-inner-wrap {
  padding-top: 32px;
  border-top: 1px solid #554671;
}
@media only screen and (max-width: 921px) {
  .site-primary-footer-inner-wrap {
    padding-top: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .site-primary-footer-inner-wrap {
    padding-top: 32px;
  }
}
.site-above-footer-inner-wrap {
  display: flex;
}
@media only screen and (max-width: 921px) {
  .site-above-footer-inner-wrap {
    flex-wrap: wrap;
  }
}
.site-footer-above-section-1 {
  width: 320px;
  margin-right: 48px;
}
@media only screen and (max-width: 921px) {
  .site-footer-above-section-1 {
    width: 100%;
    margin: 0;
  }
}
.site-footer-above-section-2 {
  width: calc(100% - 320px - 48px);
}
@media only screen and (max-width: 921px) {
  .site-footer-above-section-2 {
    width: 100%;
  }
}
.s-widget__content {
  max-width: 85vw;
  margin-left: auto;
  padding: 38px 56px;
  background: #fff;
  box-shadow: 4px 32px 38px rgba(0,0,0,0.04);
  display: flex;
  align-items: center;
  border-radius: 20px;
}
@media only screen and (max-width: 1240px) {
  .s-widget__content {
    flex-wrap: wrap;
    max-width: inherit;
    border-radius: 0;
    padding: 28px 24px;
  }
}
.s-widget__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #713ddd;
  max-width: 304px;
}
@media only screen and (max-width: 1240px) {
  .s-widget__title {
    font-size: 22px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 720px) {
  .s-widget__title {
    margin-bottom: 48px;
  }
}
.s-widget__logos-wrap {
  display: flex;
  align-items: center;
  width: calc(100% - 304px);
  justify-content: space-around;
  padding-left: 32px;
}
@media only screen and (max-width: 1240px) {
  .s-widget__logos-wrap {
    padding-left: 0;
    width: calc(100% + 54px);
    margin-left: -27px;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 720px) {
  .s-widget__logos-wrap {
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0;
  }
}
@media only screen and (max-width: 720px) {
  .s-widget__logo-wrap {
    width: 100%;
    margin-bottom: 54px;
    display: flex;
    justify-content: center;
  }
  .s-widget__logo-wrap:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 1240px) {
  .s-widget__logo {
    margin: 27px;
  }
}
@media only screen and (max-width: 720px) {
  .s-widget__logo {
    margin: 0;
  }
}
.s-widget.s-widget-about {
  background: #f9f5ff;
}
.s-widget.s-widget-about .s-widget__content {
  background: transparent;
  box-shadow: none;
}
@media only screen and (max-width: 1480px) {
  .s-widget.s-widget-about .s-widget__content {
    max-width: inherit;
  }
}
.s-widget.s-widget-about .s-widget__logo {
  mix-blend-mode: darken;
}
.s-widget.s-widget-about .s-widget__title {
  color: #19063d;
}
.s-feature {
  padding-top: 96px;
  padding-bottom: 96px;
}
@media only screen and (max-width: 1100px) {
  .s-feature {
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
.s-feature__subtitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #19063d;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1100px) {
  .s-feature__subtitle {
    font-size: 12px;
    line-height: 18px;
  }
}
.s-feature__title {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 64px;
}
@media only screen and (max-width: 1100px) {
  .s-feature__title {
    margin-bottom: 32px;
    font-size: 27px;
    line-height: 33px;
  }
}
@media only screen and (max-width: 767px) {
  .s-feature__title {
    margin-bottom: 0;
  }
}
.s-feature__blocks-wrap {
  display: flex;
  align-items: flex-start;
}
@media only screen and (max-width: 767px) {
  .s-feature__blocks-wrap {
    flex-wrap: wrap;
  }
}
.s-feature__block {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .s-feature__block {
    width: 100%;
  }
  .s-feature__block--left {
    display: flex;
    justify-content: center;
  }
}
.s-feature__block-img {
  max-width: 100%;
}
.s-feature__block-item {
  margin-bottom: 48px;
  display: flex;
  align-items: flex-start;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.3s ease;
}
.s-feature__block-item:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 1100px) {
  .s-feature__block-item {
    margin-bottom: 36px;
  }
}
.s-feature__block-item-icon {
  width: 48px;
  height: 48px;
}
@media only screen and (max-width: 1100px) {
  .s-feature__block-item-icon {
    width: 36px;
    height: 36px;
  }
}
.s-feature__block-item-content {
  width: calc(100% - 48px);
  padding-left: 12px;
}
.s-feature__block-item-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #19063d;
  margin-bottom: 8px;
  margin-top: 8px;
}
@media only screen and (max-width: 1100px) {
  .s-feature__block-item-title {
    font-size: 15px;
    line-height: 22px;
  }
}
.s-feature__block-item-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .s-feature__block-item-text {
    font-size: 12px;
    line-height: 18px;
  }
}
.s-feature__block-item-link {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #713ddd;
  margin-top: 20px;
}
.s-feature__block-item-link img {
  margin: 0 12px;
}
@media only screen and (max-width: 1100px) {
  .s-feature__block-item-link {
    margin-top: 16px;
    font-size: 12px;
    line-height: 18px;
  }
}
.s-feature .s-feature__block-item:nth-child(1) {
  transition-delay: 0.3s;
}
.s-feature .s-feature__block-item:nth-child(2) {
  transition-delay: 0.6s;
}
.s-feature .s-feature__block-item:nth-child(3) {
  transition-delay: 0.9s;
}
.s-feature .s-feature__block-item:nth-child(4) {
  transition-delay: 1.2s;
}
.s-feature .s-feature__block-item:nth-child(5) {
  transition-delay: 1.5s;
}
.s-feature.seen .s-feature__block-item {
  transform: none;
  opacity: 1;
}
.s-grades-tabs {
  padding-top: 44px;
  padding-bottom: 44px;
}
.s-grades-tabs__pretitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #19063d;
  margin-bottom: 12px;
}
.s-grades-tabs__title {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 38px;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}
.s-grades-tabs__tabs-wrap {
  display: flex;
  justify-content: center;
}
.s-grades-tabs__tabs {
  display: inline-flex;
  justify-content: center;
  width: auto;
  padding: 6px !important;
  background: #f9f5ff;
  border-radius: 48px;
  height: auto;
  gap: 8px;
}
@media only screen and (max-width: 600px) {
  .s-grades-tabs__tabs {
    width: 100%;
    flex-wrap: wrap;
    border-radius: 24px;
  }
}
.s-grades-tabs__tabs .tab {
  height: auto;
  line-height: inherit;
}
@media only screen and (max-width: 600px) {
  .s-grades-tabs__tabs .tab {
    width: 100%;
  }
}
.s-grades-tabs__tabs .tab a {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #514468 !important;
  padding: 10px 20px;
  border-radius: 48px;
  background: transparent !important;
  text-transform: none;
}
@media only screen and (max-width: 600px) {
  .s-grades-tabs__tabs .tab a {
    border-radius: 36px;
  }
}
.s-grades-tabs__tabs .tab a.active {
  background: #713ddd !important;
  box-shadow: 0px 24px 38px -4px rgba(0,0,0,0.16);
  color: #fff !important;
}
.s-grades-tabs__tabs .indicator {
  display: none !important;
}
.s-grades-tabs__blocks-wrap {
  display: flex;
  width: calc(100% + 32px);
  margin-left: -16px;
  align-items: flex-start;
}
@media only screen and (max-width: 1200px) {
  .s-grades-tabs__blocks-wrap {
    flex-wrap: wrap;
  }
}
.s-grades-tabs__block {
  margin-top: 144px;
  margin-left: 16px;
  margin-right: 16px;
  width: calc(100% / 3 - 32px);
  position: relative;
  background: #f9fafb;
  border-radius: 20px;
  padding: 80px 24px 32px;
}
@media only screen and (max-width: 1200px) {
  .s-grades-tabs__block {
    margin-top: 80px;
    width: calc(100% / 1 - 32px);
  }
}
@media only screen and (max-width: 600px) {
  .s-grades-tabs__block {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.s-grades-tabs__block-top-img {
  position: absolute;
  right: -76px;
  top: -116px;
  pointer-events: none;
}
.s-grades-tabs__block-content {
  position: relative;
  z-index: 2;
}
.s-grades-tabs__block-number-wrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
}
.s-grades-tabs__block-number {
  font-weight: 700;
  font-size: 120px;
  line-height: 140px;
  letter-spacing: -0.02em;
  color: #cbbfe3;
  font-family: 'Oswald', sans-serif;
}
.s-grades-tabs__block-number-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #cbbfe3;
  margin-left: 8px;
  margin-bottom: 8px;
}
.s-grades-tabs__block-title {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #19063d;
}
.s-grades-tabs__block-table {
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 16px 6px;
  background: #fff;
  box-shadow: 0px 32px 38px -4px rgba(0,0,0,0.07);
  border-radius: 20px;
  display: flex;
}
.s-grades-tabs__block-table-col {
  width: calc(100% / 3);
  position: relative;
  padding: 4px 10px;
}
.s-grades-tabs__block-table-col:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #eaecf0;
}
.s-grades-tabs__block-table-col:last-child:after {
  display: none;
}
.s-grades-tabs__block-table-number {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #19063d;
  margin-bottom: 4px;
}
.s-grades-tabs__block-table-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #514468;
}
.s-grades-tabs__block-list {
  position: relative;
  padding-bottom: 60px;
}
@media only screen and (max-width: 1200px) {
  .s-grades-tabs__block-list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin-left: -8px;
  }
}
.s-grades-tabs__block-list-img {
  position: absolute;
  bottom: -56px;
  right: -18px;
  pointer-events: none;
}
@media only screen and (max-width: 600px) {
  .s-grades-tabs__block-list-img {
    right: -32px;
  }
}
.s-grades-tabs__block-list-item {
  margin-bottom: 16px;
  position: relative;
  padding-left: 36px;
  z-index: 2;
}
.s-grades-tabs__block-list-item:before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../img/check.svg") center no-repeat;
  background-size: cover;
}
.s-grades-tabs__block-list-item:last-child {
  max-width: 178px;
}
@media only screen and (max-width: 1200px) {
  .s-grades-tabs__block-list-item {
    width: calc(100% / 3 - 16px);
    margin-left: 8;
    margin-right: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .s-grades-tabs__block-list-item {
    width: calc(100% / 1 - 16px);
  }
}
.s-grades-tabs__block-list-item-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  color: #19063d;
}
.s-grades-tabs__block-list-item-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #514468;
}
.s-grades-tabs__block-btns-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 540px) {
  .s-grades-tabs__block-btns-wrap {
    justify-content: center;
  }
}
.s-grades-tabs__block-btn--demo {
  background: #fff;
  box-shadow: 0px 16px 38px -4px rgba(0,0,0,0.16);
  border-radius: 48px;
  padding: 12px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  margin-right: 12px;
  margin-bottom: 12px;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .s-grades-tabs__block-btn--demo {
    width: calc(100% / 2 - 6px);
  }
}
@media only screen and (max-width: 767px) {
  .s-grades-tabs__block-btn--demo {
    width: auto;
  }
}
@media only screen and (max-width: 540px) {
  .s-grades-tabs__block-btn--demo {
    width: 100%;
    margin-right: 0;
  }
}
.s-grades-tabs__block-btn--started {
  background: #713ddd;
  border: 1px solid #713ddd;
  box-shadow: 0px 24px 38px -4px rgba(0,0,0,0.16);
  border-radius: 42px;
  padding: 10px 35px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 12px;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .s-grades-tabs__block-btn--started {
    width: calc(100% / 2 - 6px);
  }
}
@media only screen and (max-width: 767px) {
  .s-grades-tabs__block-btn--started {
    width: auto;
  }
}
@media only screen and (max-width: 540px) {
  .s-grades-tabs__block-btn--started {
    width: 100%;
  }
}
.rtl .s-grades-tabs__block-list-item {
  padding-left: auto;
  padding-right: 36px;
}
.rtl .s-grades-tabs__block-list-item:before {
  left: auto;
  right: 0;
}
.rtl .s-grades-tabs__block-list-img {
  right: auto;
  left: -18px;
}
@media only screen and (max-width: 600px) {
  .rtl .s-grades-tabs__block-list-img {
    left: -32px;
    right: auto;
  }
}
.rtl .s-grades-tabs__block-top-img {
  left: -76px;
  right: auto;
}
.rtl .s-grades-tabs__block-table-col {
  width: calc(100% / 3);
  position: relative;
  padding: 4px 10px;
}
.rtl .s-grades-tabs__block-table-col:before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #eaecf0;
}
.rtl .s-grades-tabs__block-table-col:first-child:before {
  display: none;
}
.rtl .s-grades-tabs__block-table-col:after {
  display: none;
}
.s-reviews {
  padding-top: 96px;
  padding-bottom: 96px;
  overflow: hidden;
}
@media only screen and (max-width: 1100px) {
  .s-reviews {
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
.s-reviews__title {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.01em;
  color: #19063d;
  margin-bottom: 68px;
}
@media only screen and (max-width: 1100px) {
  .s-reviews__title {
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 50px;
  }
}
.s-reviews__slider {
  padding-bottom: 88px;
  position: relative;
}
.s-reviews .slick-list {
  overflow: visible;
}
.s-reviews .slick-list:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -4000px;
  width: 4000px;
  height: 200%;
  background-color: #fff;
  z-index: 2;
}
.s-reviews .slick-arrow {
  position: absolute;
  bottom: 0;
  width: 56px;
  height: 56px;
  border: 1px solid #eaecf0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-reviews .prev-btn {
  left: 0;
}
.s-reviews .next-btn {
  left: 88px;
}
.s-reviews__slide {
  width: 360px;
  margin-right: 32px;
  border-radius: 24px;
  padding: 24px;
}
.s-reviews__slide--blue {
  background: #eaf8ff;
}
.s-reviews__slide--pink {
  background: #fbeff7;
}
.s-reviews__slide--orange {
  background: #fff6ed;
}
@media only screen and (max-width: 1100px) {
  .s-reviews__slide {
    margin-right: 24px;
    padding: 18px;
    width: 270px;
  }
}
.s-reviews__slide-content {
  padding: 16px 20px;
  background: #fff;
  box-shadow: 4px 32px 38px -4px rgba(0,0,0,0.04);
  border-radius: 20px;
}
@media only screen and (max-width: 1100px) {
  .s-reviews__slide-content {
    padding: 12px 16px;
  }
}
.s-reviews__slide-head {
  display: flex;
  margin-bottom: 20px;
}
.s-reviews__slide-head img {
  width: 60px;
  height: 60px;
  border-radius: 300px;
  margin-right: 12px;
}
.s-reviews__slide-head .info .slide-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #19063d;
}
@media only screen and (max-width: 1100px) {
  .s-reviews__slide-head .info .slide-title {
    font-size: 15px;
    line-height: 22px;
  }
}
.s-reviews__slide-head .info .slide-school-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #514468;
  opacity: 0.5;
}
.s-reviews__slide-head .info__slide-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .s-reviews__slide-head .info__slide-text {
    font-size: 14px;
    line-height: 22px;
  }
}
.rtl .s-reviews .slick-list:before {
  left: auto;
  right: -4070px;
}
.rtl .s-reviews__slide-head img {
  margin-left: 12px;
  margin-right: 0;
}
.s-faq {
  padding-bottom: 60px;
}
@media only screen and (max-width: 1100px) {
  .s-faq {
    padding-top: 56px;
    padding-bottom: 32px;
  }
}
.s-faq__content {
  max-width: 766px;
  margin-left: auto;
  margin-right: auto;
}
.s-faq__title {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1100px) {
  .s-faq__title {
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 16px;
  }
}
.s-faq__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .s-faq__description {
    font-size: 15px;
    line-height: 22px;
  }
}
.s-faq__collapse {
  margin-top: 40px;
}
@media only screen and (max-width: 1100px) {
  .s-faq__collapse {
    margin-top: 20px;
  }
}
.s-our-story {
  padding-top: 96px;
  padding-bottom: 96px;
  background: radial-gradient(89.24% 304.82% at 92.36% 150.75%, #3b2957 20.19%, #1a0643 100%);
  position: relative;
}
@media only screen and (max-width: 1240px) {
  .s-our-story {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
@media only screen and (max-width: 720px) {
  .s-our-story {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
.s-our-story__bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
.s-our-story__content {
  position: relative;
  z-index: 2;
}
.s-our-story__top-img {
  margin-bottom: 24px;
  width: 72px;
}
@media only screen and (max-width: 1240px) {
  .s-our-story__top-img {
    margin-bottom: 18px;
    width: 54px;
  }
}
@media only screen and (max-width: 720px) {
  .s-our-story__top-img {
    margin-bottom: 24px;
    width: 72px;
  }
}
.s-our-story__blocks-wrap {
  width: calc(100% + 64px);
  margin-left: -32px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.s-our-story__block {
  width: calc(100% / 3 - 64px);
  margin: 48px 32px 32px;
}
@media only screen and (max-width: 1240px) {
  .s-our-story__block {
    width: calc(100% / 2 - 64px);
  }
}
@media only screen and (max-width: 720px) {
  .s-our-story__block {
    width: calc(100% / 1 - 64px);
    margin: 64px 32px 0;
  }
}
.s-our-story__block--main {
  margin-top: 0;
}
@media only screen and (max-width: 1240px) {
  .s-our-story__block--main {
    width: calc(100% / 1 - 64px);
    margin-bottom: 0;
  }
}
.s-our-story__pretitle {
  font-size: 14px;
  line-height: 24px;
  color: #cbbfe3;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1240px) {
  .s-our-story__pretitle {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 720px) {
  .s-our-story__pretitle {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}
.s-our-story__title {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #fff;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1240px) {
  .s-our-story__title {
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 720px) {
  .s-our-story__title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 20px;
  }
}
.s-our-story__title--small {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 8px;
}
@media only screen and (max-width: 1240px) {
  .s-our-story__title--small {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 6px;
  }
}
@media only screen and (max-width: 720px) {
  .s-our-story__title--small {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;
  }
}
.s-our-story__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #cbbfe3;
}
@media only screen and (max-width: 1240px) {
  .s-our-story__description {
    font-size: 15px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 720px) {
  .s-our-story__description {
    font-size: 20px;
    line-height: 30px;
  }
}
.s-our-story__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #cbbfe3;
  margin-bottom: 20px;
}
.s-our-story__text:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 1240px) {
  .s-our-story__text {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 720px) {
  .s-our-story__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.s-team {
  padding-top: 96px;
  padding-bottom: 96px;
  position: relative;
}
@media only screen and (max-width: 1240px) {
  .s-team {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team {
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
.s-team__pretitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #19063d;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1240px) {
  .s-team__pretitle {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__pretitle {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}
.s-team__title {
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1240px) {
  .s-team__title {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__title {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 20px;
  }
}
.s-team__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #514468;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 1240px) {
  .s-team__description {
    font-size: 16px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__description {
    font-size: 20px;
    line-height: 30px;
  }
}
.s-team__blocks-wrap {
  display: flex;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: 48px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1240px) {
  .s-team__blocks-wrap {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-top: 32px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__blocks-wrap {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-top: 48px;
  }
}
.s-team__block {
  width: calc(100% / 3 - 32px);
  margin: 16px;
}
@media only screen and (max-width: 1240px) {
  .s-team__block {
    width: calc(100% / 3 - 24px);
    margin: 12px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__block {
    width: calc(100% / 1 - 32px);
    margin: 24px 16px;
  }
}
.s-team__block-photo-wrap {
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 24px;
  width: 100%;
  max-height: 296px;
}
@media only screen and (max-width: 1240px) {
  .s-team__block-photo-wrap {
    margin-bottom: 18px;
    border-radius: 18px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__block-photo-wrap {
    margin-bottom: 24px;
    border-radius: 24px;
  }
}
.s-team__block-photo-wrap img {
  max-width: 100%;
  width: 100%;
}
.s-team__block-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #19063d;
  margin-bottom: 16px;
}
@media only screen and (max-width: 1240px) {
  .s-team__block-name {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 12px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__block-name {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
  }
}
.s-team__block-position {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #514468;
}
@media only screen and (max-width: 1240px) {
  .s-team__block-position {
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__block-position {
    font-size: 16px;
    line-height: 24px;
  }
}
.s-team__block-socials-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
}
@media only screen and (max-width: 1240px) {
  .s-team__block-socials-wrap {
    margin-top: 2px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__block-socials-wrap {
    margin-top: 6px;
  }
}
.s-team__block-social {
  margin-top: 20px;
  margin-right: 20px;
  width: 24px;
}
.s-team__block-social img {
  max-width: 100%;
}
@media only screen and (max-width: 1240px) {
  .s-team__block-social {
    margin-top: 16px;
    margin-right: 16px;
    width: 18px;
  }
}
@media only screen and (max-width: 720px) {
  .s-team__block-social {
    margin-top: 20px;
    margin-right: 20px;
    width: 24px;
  }
}
.s-open-position {
  padding-top: 96px;
  padding-bottom: 96px;
  position: relative;
}
@media only screen and (max-width: 1240px) {
  .s-open-position {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
@media only screen and (max-width: 720px) {
  .s-open-position {
    padding-top: 46px;
    padding-bottom: 96px;
  }
}
.s-open-position__pretitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #19063d;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1240px) {
  .s-open-position__pretitle {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 720px) {
  .s-open-position__pretitle {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}
.s-open-position__title {
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1240px) {
  .s-open-position__title {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 720px) {
  .s-open-position__title {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 20px;
  }
}
.s-open-position__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #514468;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 1240px) {
  .s-open-position__description {
    font-size: 16px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 720px) {
  .s-open-position__description {
    font-size: 20px;
    line-height: 30px;
  }
}
.s-open-position__img-wrap {
  margin-top: 64px;
  border-radius: 24px;
  overflow: hidden;
  display: block;
}
.s-open-position__img-wrap img {
  max-width: 100%;
  width: 100%;
}
@media only screen and (max-width: 976px) {
  .s-open-position__img-wrap {
    margin-top: 48px;
    border-radius: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .s-open-position__img-wrap {
    height: 400px;
    margin-top: 64px;
    border-radius: 24px;
    position: relative;
  }
  .s-open-position__img-wrap img {
    position: absolute;
    height: 100%;
    width: auto;
    max-width: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.s-packs {
  padding-top: 0;
  padding-bottom: 96px;
  overflow: hidden;
  margin-top: -200px;
  position: relative;
  z-index: 3;
}
@media only screen and (max-width: 1100px) {
  .s-packs {
    padding-bottom: 52px;
    margin-top: 0;
  }
}
.s-packs .container {
  position: relative;
}
.s-packs__tabs-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 142px;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tabs-wrap {
    margin-bottom: 72px;
  }
}
.s-packs__tabs {
  display: inline-flex;
  justify-content: center;
  width: auto;
  padding: 6px !important;
  background: #f9f5ff;
  border-radius: 48px;
  height: auto;
  gap: 8px;
  margin-left: 0;
}
@media only screen and (max-width: 600px) {
  .s-packs__tabs {
    margin-left: auto;
    width: 100%;
    flex-wrap: wrap;
    border-radius: 24px;
  }
}
.s-packs__tabs .tab {
  height: auto;
  line-height: inherit;
}
@media only screen and (max-width: 600px) {
  .s-packs__tabs .tab {
    width: 100%;
  }
}
.s-packs__tabs .tab a {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #514468 !important;
  padding: 10px 20px;
  border-radius: 48px;
  background: transparent !important;
  text-transform: none;
}
@media only screen and (max-width: 600px) {
  .s-packs__tabs .tab a {
    border-radius: 36px;
  }
}
.s-packs__tabs .tab a.active {
  background: #713ddd !important;
  box-shadow: 0px 24px 38px -4px rgba(0,0,0,0.16);
  color: #fff !important;
}
.s-packs__tabs .indicator {
  display: none !important;
}
.s-packs__tab-result-top-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-bottom: 80px;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-top-wrap {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 600px) {
  .s-packs__tab-result-top-wrap {
    margin-bottom: 24px;
  }
}
.s-packs__tab-result-top-block {
  width: calc(100% / 2 - 64px);
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-top-block {
    width: calc(100% / 2 - 48px);
    margin-left: 24px;
    margin-right: 24px;
  }
}
@media only screen and (max-width: 600px) {
  .s-packs__tab-result-top-block {
    width: calc(100% / 1 - 48px);
  }
}
.s-packs__tab-result-title {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #19063d;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-title {
    font-size: 27px;
    line-height: 33px;
  }
}
.s-packs__tab-result-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-description {
    font-size: 15px;
    line-height: 22px;
  }
}
.s-packs__tab-result-blocks-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 32px);
  margin-left: -16px;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-blocks-wrap {
    width: calc(100% + 24px);
    margin-left: -12px;
  }
}
.s-packs__tab-result-block {
  width: calc(100% / 3 - 32px);
  margin: 16px;
  background: #f9fafb;
  border-radius: 20px;
  overflow: hidden;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-block {
    width: calc(100% / 2 - 24px);
    margin: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .s-packs__tab-result-block {
    width: calc(100% / 1 - 24px);
  }
}
.s-packs__tab-result-block-img-wrap {
  position: relative;
  box-shadow: 0px 32px 38px -4px rgba(0,0,0,0.07);
  border-radius: 20px;
  overflow: hidden;
  height: 256px;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-block-img-wrap {
    height: 232px;
  }
}
.s-packs__tab-result-block-img-wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.s-packs__tab-result-block-counter {
  position: absolute;
  top: 16px;
  left: 24px;
  padding: 10px 10px 10px 46px;
  background: #f9f5ff;
  border-radius: 32px;
  z-index: 2;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-block-counter {
    left: 20px;
  }
}
.s-packs__tab-result-block-counter-icon {
  background: #fff;
  box-shadow: 0px 9.6px 22.8px -2.4px rgba(0,0,0,0.16);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: 2px;
  padding: 7px;
}
.s-packs__tab-result-block-counter-icon img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.s-packs__tab-result-block-counter-number {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #19063d;
}
.s-packs__tab-result-block-text-wrap {
  padding: 24px 24px 30px 24px;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-block-text-wrap {
    padding: 18px 18px 22px 18px;
  }
}
.s-packs__tab-result-block-pretitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #cbbfe3;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-block-pretitle {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 8px;
  }
}
.s-packs__tab-result-block-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #19063d;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-block-title {
    font-size: 18px;
    line-height: 24px;
  }
}
.s-packs__tab-result-coming-wrap {
  height: 680px;
  position: relative;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-coming-wrap {
    height: 400px;
  }
}
@media only screen and (max-width: 740px) {
  .s-packs__tab-result-coming-wrap {
    height: auto;
    flex-wrap: wrap;
  }
}
.s-packs__tab-result-coming-img {
  position: absolute;
  left: -150px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-coming-img {
    left: 0;
    width: 400px;
  }
}
@media only screen and (max-width: 740px) {
  .s-packs__tab-result-coming-img {
    position: static;
    transform: none;
    width: 100%;
    max-width: 100%;
  }
}
.s-packs__tab-result-coming-text-wrap {
  max-width: 540px;
  margin-left: auto;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-coming-text-wrap {
    max-width: 334px;
  }
}
@media only screen and (max-width: 740px) {
  .s-packs__tab-result-coming-text-wrap {
    max-width: inherit;
    width: 100%;
    margin-bottom: 40px;
  }
}
.s-packs__tab-result-coming-title {
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-coming-title {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 18px;
  }
}
.s-packs__tab-result-coming-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .s-packs__tab-result-coming-text {
    font-size: 15px;
    line-height: 22px;
  }
}
.pagination {
  margin-top: 48px;
  display: flex;
  justify-content: center;
  padding-left: 90px;
  padding-right: 90px;
  position: relative;
  padding-bottom: 0;
  flex-wrap: wrap;
  padding-top: 20px;
  border-top: 1px solid #eaecf0;
}
@media only screen and (max-width: 740px) {
  .pagination {
    padding-top: 0;
    border-top: 0;
    padding-left: 50px;
    padding-right: 50px;
  }
}
.pagination .prev,
.pagination .current.prev,
.pagination .page-link.prev {
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #514468;
  padding-left: 24px;
  border: none;
  background: none;
  box-shadow: none;
  top: 20px;
  left: 0;
  width: auto !important;
  cursor: pointer;
}
.pagination .prev:before,
.pagination .current.prev:before,
.pagination .page-link.prev:before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../img/s-arrow-prev.svg") center no-repeat;
  background-size: cover;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.pagination .next,
.pagination .current.next,
.pagination .page-link.next {
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #514468;
  padding-right: 24px;
  border: none;
  background: none;
  box-shadow: none;
  top: 20px;
  right: 0;
  width: auto !important;
  cursor: pointer;
}
.pagination .next:before,
.pagination .current.next:before,
.pagination .page-link.next:before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../img/s-arrow-next.svg") center no-repeat;
  background-size: cover;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.pagination .ellipse,
.pagination .page-link {
  width: 40px;
  height: 40px;
  margin: 0;
  border: none;
  background: none;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #19063d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.pagination .current {
  width: 40px;
  height: 40px;
  margin: 0;
  border: none;
  background: #f9fafb;
  border-radius: 8px;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #19063d;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 740px) {
  .pagination .prev,
  .pagination .next,
  .pagination .current.prev,
  .pagination .page-link.prev,
  .pagination .current.next,
  .pagination .page-link.next,
  .pagination .current {
    display: flex !important;
    width: auto;
    background: none;
  }
  .pagination .current:before {
    position: relative;
    content: 'Page';
    display: block;
    left: 0;
    margin-right: 6px;
  }
  .pagination .prev,
  .pagination .next,
  .pagination .current.prev,
  .pagination .page-link.prev,
  .pagination .current.next,
  .pagination .page-link.next {
    font-size: 0;
    line-height: 0;
    width: 36px;
    height: 36px;
    background: #fff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16,24,40,0.05);
    border-radius: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  .pagination .prev:before,
  .pagination .next:before,
  .pagination .current.prev:before,
  .pagination .page-link.prev:before,
  .pagination .current.next:before,
  .pagination .page-link.next:before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .pagination .ellipse,
  .pagination .page-link {
    display: none;
  }
  .pagination .ellipse:nth-last-child(2),
  .pagination .page-link:nth-last-child(2) {
    display: flex !important;
    width: auto;
    padding-left: 0;
  }
  .pagination .ellipse:nth-last-child(2):before,
  .pagination .page-link:nth-last-child(2):before {
    position: relative;
    content: 'of';
    display: block;
    left: 0;
    margin-right: 6px;
  }
}
.modal {
  width: 100%;
  max-width: 1440px;
  max-height: 100vh;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
  box-shadow: 60px 76px 150px rgba(52,64,84,0.12);
  border-radius: 20px;
}
@media only screen and (max-width: 1460px) {
  .modal {
    max-width: 100%;
  }
}
.modal .modal-cross {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  z-index: 10;
}
.modal .modal-cross--m {
  display: none;
}
@media only screen and (max-width: 760px) {
  .modal .modal-cross--m {
    display: flex;
  }
  .modal .modal-cross--d {
    display: none;
  }
}
.modal .modal-content {
  padding: 0;
}
.modal .modal-sections-wrap {
  display: flex;
}
@media only screen and (max-width: 760px) {
  .modal .modal-sections-wrap {
    flex-wrap: wrap;
  }
}
.modal .modal-section {
  padding: 38px 32px;
}
@media only screen and (max-width: 992px) {
  .modal .modal-section {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media only screen and (max-width: 760px) {
  .modal .modal-section {
    padding: 32px 16px;
  }
}
.modal .modal-section__logo-wrap {
  margin-bottom: 32px;
}
.modal .modal-section--form {
  width: 640px;
  background: #fff;
}
@media only screen and (max-width: 1200px) {
  .modal .modal-section--form {
    width: 50%;
  }
}
@media only screen and (max-width: 992px) {
  .modal .modal-section--form {
    width: 384px;
  }
}
@media only screen and (max-width: 760px) {
  .modal .modal-section--form {
    width: 100%;
  }
}
.modal .modal-section--banner {
  width: calc(100% - 640px);
  background: radial-gradient(96.49% 87.45% at 97.43% 5.26%, #3b2957 19.47%, #1a0643 100%);
  position: relative;
  padding: 114px 96px 168px;
}
@media only screen and (max-width: 1200px) {
  .modal .modal-section--banner {
    padding-left: 48px;
    padding-right: 48px;
    width: 50%;
  }
}
@media only screen and (max-width: 992px) {
  .modal .modal-section--banner {
    width: calc(100% - 384px);
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media only screen and (max-width: 760px) {
  .modal .modal-section--banner {
    padding-top: 68px;
    width: 100%;
  }
}
.modal .brand-logo {
  display: inline-flex;
}
.modal .modal-section-form__title {
  margin-top: 48px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #19063d;
}
@media only screen and (max-width: 992px) {
  .modal .modal-section-form__title {
    font-size: 18px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 760px) {
  .modal .modal-section-form__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.modal .modal-section-form__bootom-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #a4a3a8;
  text-align: center;
  margin-top: 32px;
}
.modal .modal-section-form__bootom-text a {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #713ddd;
  text-decoration: none;
}
.modal .modal-section-form__copyright {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #a4a3a8;
  margin-top: 24px;
}
.modal .modal-section-banner__bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal .modal-section-banner__content {
  position: relative;
  z-index: 2;
}
.modal .modal-section-banner__star-wrap {
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
@media only screen and (max-width: 992px) {
  .modal .modal-section-banner__star-wrap {
    width: 54px;
    height: 54px;
    margin-bottom: 24px;
  }
}
.modal .modal-section-banner__star-wrap img {
  max-width: 100%;
}
.modal .modal-section-banner__title {
  font-weight: 800;
  font-size: 72px;
  line-height: 90px;
  letter-spacing: -0.02em;
  color: #fff;
  margin-bottom: 24px;
}
@media only screen and (max-width: 992px) {
  .modal .modal-section-banner__title {
    font-size: 54px;
    line-height: 68px;
    margin-bottom: 18px;
  }
}
.modal .modal-section-banner__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #eaecf0;
}
@media only screen and (max-width: 992px) {
  .modal .modal-section-banner__text {
    font-size: 14px;
    line-height: 22px;
  }
}
.modal .modal-section-banner__avatars-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 32px;
}
@media only screen and (max-width: 992px) {
  .modal .modal-section-banner__avatars-block {
    margin-top: 24px;
  }
}
.modal .modal-section-banner__avatars-img-wrap {
  margin-right: 16px;
  margin-bottom: 16px;
  width: 174px;
}
@media only screen and (max-width: 992px) {
  .modal .modal-section-banner__avatars-img-wrap {
    width: 112px;
  }
}
.modal .modal-section-banner__avatars-img-wrap img {
  max-width: 100%;
}
.modal .modal-section-banner__avatars-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #eaecf0;
  margin-bottom: 16px;
}
@media only screen and (max-width: 992px) {
  .modal .modal-section-banner__avatars-text {
    font-size: 12px;
    line-height: 18px;
  }
}
.modal .modal-section-banner__arrow {
  position: absolute;
  pointer-events: none;
  bottom: 48px;
  left: -48px;
}
@media only screen and (max-width: 992px) {
  .modal .modal-section-banner__arrow {
    display: none;
  }
}
.s-contact-header {
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 460px;
}
@media only screen and (max-width: 1100px) {
  .s-contact-header {
    min-height: 412px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact-header {
    min-height: 396px;
  }
}
.s-contact-header__content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.s-contact-header__bg-wrap {
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.s-contact-header__bg-wrap img {
  width: 100%;
  min-width: 968px;
}
.s-contact-header__text-wrap {
  position: relative;
  z-index: 2;
}
.s-contact-header__pretitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #19063d;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1100px) {
  .s-contact-header__pretitle {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 8px;
  }
}
.s-contact-header__title {
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1100px) {
  .s-contact-header__title {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 18px;
  }
}
.s-contact-header__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .s-contact-header__text {
    font-size: 15px;
    line-height: 22px;
  }
}
.s-contact-us {
  padding: 0;
}
.s-contact-us__content {
  display: flex;
}
@media only screen and (max-width: 760px) {
  .s-contact-us__content {
    flex-wrap: wrap;
  }
}
.s-contact-us__block {
  width: 50%;
}
@media only screen and (max-width: 760px) {
  .s-contact-us__block {
    width: 100%;
  }
}
.s-contact-us__map {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 760px) {
  .s-contact-us__map {
    min-height: 655px;
  }
}
.s-contact-us__visit-us {
  position: relative;
  padding: 112px 96px;
  background: radial-gradient(89.24% 304.82% at 92.36% 150.75%, #3b2957 20.19%, #1a0643 100%);
}
@media only screen and (max-width: 1100px) {
  .s-contact-us__visit-us {
    padding: 72px 24px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact-us__visit-us {
    padding: 72px 16px;
  }
}
.s-contact-us__form-wrap {
  background: #fff;
  padding: 72px 96px;
}
@media only screen and (max-width: 1100px) {
  .s-contact-us__form-wrap {
    padding: 54px 24px;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact-us__form-wrap {
    padding: 54px 16px;
  }
}
.s-contact-us .s-contact-us-visit-us__bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.s-contact-us .s-contact-us-visit-us__pretitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #cbbfe3;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1100px) {
  .s-contact-us .s-contact-us-visit-us__pretitle {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 8px;
  }
}
.s-contact-us .s-contact-us-visit-us__title {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #fff;
  margin-bottom: 32px;
}
@media only screen and (max-width: 1100px) {
  .s-contact-us .s-contact-us-visit-us__title {
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 24px;
  }
}
.s-contact-us .s-contact-us-visit-us__city {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 8px;
}
@media only screen and (max-width: 1100px) {
  .s-contact-us .s-contact-us-visit-us__city {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 6px;
  }
}
.s-contact-us .s-contact-us-visit-us__adress {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #cbbfe3;
}
@media only screen and (max-width: 1100px) {
  .s-contact-us .s-contact-us-visit-us__adress {
    font-size: 12px;
    line-height: 18px;
  }
}
.s-contact-us .s-contact-us-visit-us__link-wrap {
  display: flex;
}
.s-contact-us .s-contact-us-visit-us__link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #cbbfe3;
  text-decoration: none;
}
@media only screen and (max-width: 1100px) {
  .s-contact-us .s-contact-us-visit-us__link {
    font-size: 12px;
    line-height: 18px;
  }
}
.s-contact-us .s-contact-us-form__title {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1100px) {
  .s-contact-us .s-contact-us-form__title {
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 16px;
  }
}
.s-contact-us .s-contact-us-form__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .s-contact-us .s-contact-us-form__text {
    font-size: 15px;
    line-height: 22px;
  }
}
.s-contact-us .s-contact-us-form__text a {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: #713ddd;
  text-decoration: none;
}
.s-contact-header {
  padding: 0 !important;
}
.s-contact-header .s-contact-header__text-wrap p {
  margin-top: 12px;
  font-size: 20px;
  line-height: 30px;
}
.s-contact-us {
  padding: 0 !important;
}
.single-curriculum .s-header-block .pre-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 976px) {
  .single-curriculum .s-header-block .pre-title {
    font-size: 10.5px;
    line-height: 18px;
    margin-bottom: 12px;
  }
}
.single-curriculum .s-header-block h1 {
  font-weight: 800;
  font-size: 72px !important;
  line-height: 90px;
  margin-bottom: 58px;
  margin-top: 0;
}
@media only screen and (max-width: 976px) {
  .single-curriculum .s-header-block h1 {
    font-size: 54px;
    line-height: 68px;
    margin-bottom: 43px;
  }
}
@media only screen and (max-width: 767px) {
  .single-curriculum .s-header-block h1 {
    margin-bottom: 24px;
  }
}
.single-curriculum .s-header-block h1 strong {
  font-weight: 800;
  font-size: 72px !important;
  color: #713ddd !important;
}
@media only screen and (max-width: 976px) {
  .single-curriculum .s-header-block h1 strong {
    font-size: 54px !important;
  }
}
.single-curriculum .s-header-block .after-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #cbbfe3;
  margin-bottom: 8px;
}
@media only screen and (max-width: 976px) {
  .single-curriculum .s-header-block .after-title {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 12px;
  }
}
.single-curriculum .s-header-block .after-title strong {
  font-weight: 700;
  font-size: 120px;
  line-height: 140px;
}
@media only screen and (max-width: 976px) {
  .single-curriculum .s-header-block .after-title strong {
    font-size: 90px;
    line-height: 105px;
  }
}
.single-curriculum .s-header-block .label {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
}
@media only screen and (max-width: 976px) {
  .single-curriculum .s-header-block .label {
    font-size: 27px;
    line-height: 33px;
  }
}
.single-curriculum .s-header-block .info-block {
  box-shadow: 0px 24px 28.5px -3px rgba(0,0,0,0.07);
  border-radius: 15px;
}
.single-curriculum .s-header-block .info-block h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  margin-bottom: 4px;
}
.single-curriculum .s-header-block .info-block p {
  font-size: 16px;
  line-height: 20px;
}
.single-curriculum .s-header-block .info-block .border-right {
  border-right: 1px solid #eaecf0;
}
.s-description-block .wp-block-uagb-icon-list .uagb-icon-list__label {
  font-size: 18px;
  line-height: 28px;
}
.author .post-featured-image img,
.single-post .post-featured-image img {
  width: 100%;
  object-fit: contain;
}
.author .post-conclusion,
.single-post .post-conclusion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 32px 40px !important;
  gap: 24px;
}
@media only screen and (max-width: 976px) {
  .author .post-conclusion,
  .single-post .post-conclusion {
    padding: 24px 24px 30px !important;
    gap: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .author .post-conclusion,
  .single-post .post-conclusion {
    padding: 24px 20px 32px !important;
    gap: 24px;
  }
}
.author .post-inner,
.single-post .post-inner {
  max-width: 748px;
  margin: 0 auto;
  padding: 14px;
  border-bottom: 1px solid #eaecf0;
}
@media only screen and (max-width: 767px) {
  .author .post-inner,
  .single-post .post-inner {
    padding: 6px;
  }
}
.author .entry-meta .updated,
.single-post .entry-meta .updated {
  display: none;
}
.page-template-blog .page-descr,
.author .page-descr,
.post-template-default .page-descr {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.page-template-blog .b-wrapper,
.author .b-wrapper,
.post-template-default .b-wrapper {
  padding-top: 216px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .b-wrapper,
  .author .b-wrapper,
  .post-template-default .b-wrapper {
    padding-top: 169px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .b-wrapper,
  .author .b-wrapper,
  .post-template-default .b-wrapper {
    padding-top: 136px;
  }
}
.page-template-blog .entry-meta,
.author .entry-meta,
.post-template-default .entry-meta {
  text-align: center;
  margin-bottom: 12px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .entry-meta,
  .author .entry-meta,
  .post-template-default .entry-meta {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .entry-meta,
  .author .entry-meta,
  .post-template-default .entry-meta {
    margin-bottom: 12px;
  }
}
.page-template-blog .entry-meta span,
.author .entry-meta span,
.post-template-default .entry-meta span,
.page-template-blog .entry-meta a,
.author .entry-meta a,
.post-template-default .entry-meta a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #cbbfe3;
  text-decoration: none;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .entry-meta span,
  .author .entry-meta span,
  .post-template-default .entry-meta span,
  .page-template-blog .entry-meta a,
  .author .entry-meta a,
  .post-template-default .entry-meta a {
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .entry-meta span,
  .author .entry-meta span,
  .post-template-default .entry-meta span,
  .page-template-blog .entry-meta a,
  .author .entry-meta a,
  .post-template-default .entry-meta a {
    font-size: 14px;
    line-height: 20px;
  }
}
.page-template-blog .entry-meta .author-name,
.author .entry-meta .author-name,
.post-template-default .entry-meta .author-name {
  position: relative;
  padding-right: 20px;
}
.page-template-blog .entry-meta .author-name:after,
.author .entry-meta .author-name:after,
.post-template-default .entry-meta .author-name:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 6px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #cbbfe3;
  transform: translateY(-50%);
}
.page-template-blog h1,
.author h1,
.post-template-default h1 {
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #19063d;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 976px) {
  .page-template-blog h1,
  .author h1,
  .post-template-default h1 {
    margin-bottom: 18px;
    font-size: 36px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog h1,
  .author h1,
  .post-template-default h1 {
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 44px;
  }
}
.page-template-blog h2,
.author h2,
.post-template-default h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #19063d;
  margin-bottom: 24px;
  margin-top: 48px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog h2,
  .author h2,
  .post-template-default h2 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 18px;
    margin-top: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog h2,
  .author h2,
  .post-template-default h2 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    margin-top: 48px;
  }
}
.page-template-blog h3,
.author h3,
.post-template-default h3 {
  margin-top: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #19063d;
  margin-bottom: 24px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog h3,
  .author h3,
  .post-template-default h3 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog h3,
  .author h3,
  .post-template-default h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
}
.page-template-blog ul,
.author ul,
.post-template-default ul,
.page-template-blog ol,
.author ol,
.post-template-default ol,
.page-template-blog p,
.author p,
.post-template-default p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #514468;
  margin-bottom: 24px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog ul,
  .author ul,
  .post-template-default ul,
  .page-template-blog ol,
  .author ol,
  .post-template-default ol,
  .page-template-blog p,
  .author p,
  .post-template-default p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog ul,
  .author ul,
  .post-template-default ul,
  .page-template-blog ol,
  .author ol,
  .post-template-default ol,
  .page-template-blog p,
  .author p,
  .post-template-default p {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 28px;
  }
}
.page-template-blog .post-bottom,
.author .post-bottom,
.post-template-default .post-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 60px;
}
.page-template-blog .post-author,
.author .post-author,
.post-template-default .post-author {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .page-template-blog .post-author,
  .author .post-author,
  .post-template-default .post-author {
    margin-bottom: 26px;
    width: 100%;
  }
}
.page-template-blog .post-author p,
.author .post-author p,
.post-template-default .post-author p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #514468;
}
.page-template-blog .post-author .author-name,
.author .post-author .author-name,
.post-template-default .post-author .author-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #19063d;
}
.page-template-blog .post-author .gravatar,
.author .post-author .gravatar,
.post-template-default .post-author .gravatar {
  margin-right: 16px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
}
.page-template-blog .post-author .gravatar img,
.author .post-author .gravatar img,
.post-template-default .post-author .gravatar img {
  max-width: 100%;
  max-height: 100%;
}
.page-template-blog .post-conclusion,
.author .post-conclusion,
.post-template-default .post-conclusion {
  gap: 0 !important;
  margin-bottom: 48px !important;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .post-conclusion,
  .author .post-conclusion,
  .post-template-default .post-conclusion {
    margin-bottom: 36px !important;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .post-conclusion,
  .author .post-conclusion,
  .post-template-default .post-conclusion {
    margin-bottom: 48px !important;
  }
}
.page-template-blog .wp-block-image,
.author .wp-block-image,
.post-template-default .wp-block-image {
  margin-bottom: 48px;
  margin-top: 48px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .wp-block-image,
  .author .wp-block-image,
  .post-template-default .wp-block-image {
    margin-bottom: 36px;
    margin-top: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .wp-block-image,
  .author .wp-block-image,
  .post-template-default .wp-block-image {
    margin-bottom: 48px;
    margin-top: 48px;
  }
}
.page-template-blog .wp-block-image img,
.author .wp-block-image img,
.post-template-default .wp-block-image img {
  border-radius: 20px;
  width: auto;
  height: auto;
  max-width: 100%;
}
.page-template-blog .wp-element-caption,
.author .wp-element-caption,
.post-template-default .wp-element-caption {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #514468;
  margin-bottom: 0;
  margin-top: 16px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .wp-element-caption,
  .author .wp-element-caption,
  .post-template-default .wp-element-caption {
    margin-top: 12px;
  }
}
.page-template-blog .wp-element-caption a,
.author .wp-element-caption a,
.post-template-default .wp-element-caption a {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: underline;
  color: inherit;
}
.page-template-blog .wp-block-quote,
.author .wp-block-quote,
.post-template-default .wp-block-quote {
  position: relative;
  padding-left: 22px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 36px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .wp-block-quote,
  .author .wp-block-quote,
  .post-template-default .wp-block-quote {
    padding-left: 17px;
  }
}
.page-template-blog .wp-block-quote:before,
.author .wp-block-quote:before,
.post-template-default .wp-block-quote:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2px;
  background-color: #713ddd;
}
.page-template-blog .wp-block-quote p,
.author .wp-block-quote p,
.post-template-default .wp-block-quote p {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 32px;
  color: #19063d;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .wp-block-quote p,
  .author .wp-block-quote p,
  .post-template-default .wp-block-quote p {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .wp-block-quote p,
  .author .wp-block-quote p,
  .post-template-default .wp-block-quote p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 32px;
  }
}
.page-template-blog .wp-block-quote cite,
.author .wp-block-quote cite,
.post-template-default .wp-block-quote cite {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #514468;
  font-style: normal;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .wp-block-quote cite,
  .author .wp-block-quote cite,
  .post-template-default .wp-block-quote cite {
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .wp-block-quote cite,
  .author .wp-block-quote cite,
  .post-template-default .wp-block-quote cite {
    font-size: 16px;
    line-height: 24px;
  }
}
.page-template-blog .s-posts__tab-result-block-tags,
.author .s-posts__tab-result-block-tags,
.post-template-default .s-posts__tab-result-block-tags,
.page-template-blog .post-tags,
.author .post-tags,
.post-template-default .post-tags {
  margin-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .s-posts__tab-result-block-tags,
  .author .s-posts__tab-result-block-tags,
  .post-template-default .s-posts__tab-result-block-tags,
  .page-template-blog .post-tags,
  .author .post-tags,
  .post-template-default .post-tags {
    margin-bottom: 44px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .s-posts__tab-result-block-tags,
  .author .s-posts__tab-result-block-tags,
  .post-template-default .s-posts__tab-result-block-tags,
  .page-template-blog .post-tags,
  .author .post-tags,
  .post-template-default .post-tags {
    margin-bottom: 32px;
  }
}
.page-template-blog .s-posts__tab-result-block-tags span,
.author .s-posts__tab-result-block-tags span,
.post-template-default .s-posts__tab-result-block-tags span,
.page-template-blog .post-tags span,
.author .post-tags span,
.post-template-default .post-tags span,
.page-template-blog .s-posts__tab-result-block-tags a,
.author .s-posts__tab-result-block-tags a,
.post-template-default .s-posts__tab-result-block-tags a,
.page-template-blog .post-tags a,
.author .post-tags a,
.post-template-default .post-tags a {
  margin: 4px;
  padding: 2px 10px;
  background: #f9f5ff;
  border-radius: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #713ddd;
}
.page-template-blog .post-featured-image,
.author .post-featured-image,
.post-template-default .post-featured-image {
  margin-bottom: 96px;
  overflow: hidden;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .post-featured-image,
  .author .post-featured-image,
  .post-template-default .post-featured-image {
    margin-bottom: 72px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .post-featured-image,
  .author .post-featured-image,
  .post-template-default .post-featured-image {
    margin-bottom: 64px;
  }
}
.page-template-blog .post-featured-image img,
.author .post-featured-image img,
.post-template-default .post-featured-image img {
  border-radius: 20px;
  width: auto;
  height: auto;
  max-width: 100%;
}
.page-template-blog .post-share__socials,
.author .post-share__socials,
.post-template-default .post-share__socials {
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.page-template-blog .post-share__socials li,
.author .post-share__socials li,
.post-template-default .post-share__socials li {
  margin: 6px;
}
.page-template-blog .post-share__socials li a,
.author .post-share__socials li a,
.post-template-default .post-share__socials li a {
  padding: 10px;
  background: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16,24,40,0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-template-blog .post-share__socials li a img,
.author .post-share__socials li a img,
.post-template-default .post-share__socials li a img {
  width: 20px;
  height: 20px;
}
.page-template-blog .post-share__socials .copy-link,
.author .post-share__socials .copy-link,
.post-template-default .post-share__socials .copy-link {
  padding: 10px 16px 10px 44px;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #514468;
  background: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16,24,40,0.05);
  border-radius: 8px;
}
.page-template-blog .post-share__socials .copy-link:before,
.author .post-share__socials .copy-link:before,
.post-template-default .post-share__socials .copy-link:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 17px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: url("../img/copy.svg") center no-repeat;
  background-size: cover;
}
.page-template-blog .post-excerpt,
.author .post-excerpt,
.post-template-default .post-excerpt {
  padding-bottom: 32px;
  margin-bottom: 0;
  border-bottom: 1px solid #eaecf0;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .post-excerpt,
  .author .post-excerpt,
  .post-template-default .post-excerpt {
    padding-bottom: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .post-excerpt,
  .author .post-excerpt,
  .post-template-default .post-excerpt {
    padding-bottom: 32px;
    padding-left: 6px;
    padding-right: 6px;
  }
}
.page-template-blog .post-excerpt p,
.author .post-excerpt p,
.post-template-default .post-excerpt p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #514468;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .post-excerpt p,
  .author .post-excerpt p,
  .post-template-default .post-excerpt p {
    font-size: 15px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-blog .post-excerpt p,
  .author .post-excerpt p,
  .post-template-default .post-excerpt p {
    font-size: 18px;
    line-height: 28px;
  }
}
.page-template-blog .s-posts__tab-result-block-text-wrap {
  position: relative;
  max-width: 608px;
}
.page-template-blog .s-posts__tab-result-block-text-wrap:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 70px;
  right: 0;
  background: url("../img/post-arrow.svg") center no-repeat;
  background-size: cover;
}
.page-template-blog .s-posts__tab-result-block-tags {
  justify-content: flex-start;
}
.page-template-blog .entry-meta {
  text-align: left;
}
.page-template-blog .s-posts__tab-result-block-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #19063d;
  margin-bottom: 12px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .s-posts__tab-result-block-title {
    font-size: 22px;
    line-height: 28px;
  }
}
.page-template-blog .s-posts__tab-result-block-excerpt {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #514468;
  margin-bottom: 18px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .s-posts__tab-result-block-excerpt {
    font-size: 12px;
    line-height: 18px;
  }
}
.page-template-blog .s-posts__tab-result-block-text-wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.page-template-blog .s-latest-post .s-posts__tab-result-block-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #19063d;
  margin-bottom: 12px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .s-latest-post .s-posts__tab-result-block-title {
    font-size: 22px;
    line-height: 28px;
  }
}
.page-template-blog .s-latest-post .s-posts__tab-result-block-excerpt {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #514468;
  margin-bottom: 18px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .s-latest-post .s-posts__tab-result-block-excerpt {
    font-size: 12px;
    line-height: 18px;
  }
}
.page-template-blog .s-posts {
  padding-top: 0;
  padding-bottom: 96px;
  overflow: hidden;
  position: relative;
  z-index: 3;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts {
    padding-bottom: 52px;
    margin-top: 0;
  }
}
.page-template-blog .s-posts .container {
  position: relative;
}
.page-template-blog .s-posts__tabs-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
}
.page-template-blog .s-posts__tabs {
  display: inline-flex;
  justify-content: center;
  width: auto;
  padding: 6px !important;
  background: #f9f5ff;
  border-radius: 48px;
  height: auto;
  gap: 8px;
  margin-left: 0;
}
@media only screen and (max-width: 600px) {
  .page-template-blog .s-posts__tabs {
    margin-left: auto;
    width: 100%;
    flex-wrap: wrap;
    border-radius: 24px;
  }
}
.page-template-blog .s-posts__tabs .tab {
  height: auto;
  line-height: inherit;
}
@media only screen and (max-width: 600px) {
  .page-template-blog .s-posts__tabs .tab {
    width: 100%;
  }
}
.page-template-blog .s-posts__tabs .tab a {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #514468 !important;
  padding: 10px 20px;
  border-radius: 48px;
  background: transparent !important;
  text-transform: none;
}
@media only screen and (max-width: 600px) {
  .page-template-blog .s-posts__tabs .tab a {
    border-radius: 36px;
  }
}
.page-template-blog .s-posts__tabs .tab a.active {
  background: #713ddd !important;
  box-shadow: 0px 24px 38px -4px rgba(0,0,0,0.16);
  color: #fff !important;
}
.page-template-blog .s-posts__tabs .indicator {
  display: none !important;
}
.page-template-blog .s-posts__tab-result-top-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-bottom: 80px;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-top-wrap {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 600px) {
  .page-template-blog .s-posts__tab-result-top-wrap {
    margin-bottom: 24px;
  }
}
.page-template-blog .s-posts__tab-result-top-block {
  width: calc(100% / 2 - 64px);
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-top-block {
    width: calc(100% / 2 - 48px);
    margin-left: 24px;
    margin-right: 24px;
  }
}
@media only screen and (max-width: 600px) {
  .page-template-blog .s-posts__tab-result-top-block {
    width: calc(100% / 1 - 48px);
  }
}
.page-template-blog .s-posts__tab-result-title {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #19063d;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-title {
    font-size: 27px;
    line-height: 33px;
  }
}
.page-template-blog .s-posts__tab-result-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-description {
    font-size: 15px;
    line-height: 22px;
  }
}
.page-template-blog .s-posts__tab-result-blocks-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 32px);
  margin-left: -16px;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-blocks-wrap {
    width: calc(100% + 24px);
    margin-left: -12px;
  }
}
.page-template-blog .s-posts__tab-result-block {
  width: calc(100% / 3 - 32px);
  margin: 16px;
  overflow: hidden;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-block {
    width: calc(100% / 2 - 24px);
    margin: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .page-template-blog .s-posts__tab-result-block {
    width: calc(100% / 1 - 24px);
  }
}
.page-template-blog .s-posts__tab-result-block-img-wrap {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  height: 240px;
}
@media only screen and (max-width: 976px) {
  .page-template-blog .s-posts__tab-result-block-img-wrap {
    height: 180px;
  }
}
.page-template-blog .s-posts__tab-result-block-img-wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.page-template-blog .s-posts__tab-result-block-text-wrap {
  padding: 24px 24px 30px 24px;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-block-text-wrap {
    padding: 18px 18px 22px 18px;
  }
}
.page-template-blog .s-posts__tab-result-block-pretitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #cbbfe3;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-block-pretitle {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 8px;
  }
}
.page-template-blog .s-posts__tab-result-block-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #19063d;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-block-title {
    font-size: 18px;
    line-height: 24px;
  }
}
.page-template-blog .s-posts__tab-result-coming-wrap {
  height: 680px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-coming-wrap {
    height: 400px;
  }
}
@media only screen and (max-width: 740px) {
  .page-template-blog .s-posts__tab-result-coming-wrap {
    height: auto;
    flex-wrap: wrap;
  }
}
.page-template-blog .s-posts__tab-result-coming-text-wrap {
  max-width: 540px;
  margin-left: 16px;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-coming-text-wrap {
    max-width: 334px;
  }
}
@media only screen and (max-width: 740px) {
  .page-template-blog .s-posts__tab-result-coming-text-wrap {
    max-width: inherit;
    width: 100%;
    margin-bottom: 40px;
  }
}
.page-template-blog .s-posts__tab-result-coming-title {
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #19063d;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-coming-title {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 18px;
  }
}
.page-template-blog .s-posts__tab-result-coming-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #514468;
}
@media only screen and (max-width: 1100px) {
  .page-template-blog .s-posts__tab-result-coming-text {
    font-size: 15px;
    line-height: 22px;
  }
}
.wpforms-container .wpforms-form .wpforms-field {
  padding: 0;
  padding-bottom: 10px;
}
.wpforms-container .wpforms-form .wpforms-field .wpforms-field-description,
.wpforms-container .wpforms-form .wpforms-field .wpforms-field-limit-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #a4a3a8;
}
.wpforms-container .wpforms-form .wpforms-field input[type=text],
.wpforms-container .wpforms-form .wpforms-field input[type=email],
.wpforms-container .wpforms-form .wpforms-field input[type=tel],
.wpforms-container .wpforms-form .wpforms-field select,
.wpforms-container .wpforms-form .wpforms-field textarea {
  padding: 10px !important;
  height: 44px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #19063d !important;
  background: #fbfaf6 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: none !important;
}
.wpforms-container .wpforms-form .wpforms-field .wpforms-field-date-time-date-month,
.wpforms-container .wpforms-form .wpforms-field .wpforms-field-date-time-date-day {
  width: 25%;
}
.wpforms-container .wpforms-form .wpforms-field .wpforms-field-date-time-date-year {
  width: 40%;
}
.wpforms-container .wpforms-form .wpforms-field .wpforms-field-date-time-date-sep {
  width: 5%;
  color: #fff;
}
.wpforms-container .wpforms-form .wpforms-field .wpforms-field-label {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #19063d !important;
}
.wpforms-container .wpforms-form .wpforms-field .wpforms-required-label {
  color: #19063d !important;
}
.wpforms-container .wpforms-form .wpforms-field .choices .choices__inner {
  min-height: 44px;
  border-radius: 8px;
  background: #fbfaf6;
  border: none;
}
.wpforms-container .wpforms-form .wpforms-field .choices .choices__inner .choices__list--multiple .choices__item {
  background: #fff !important;
  border: 1px solid #f5f1e1 !important;
  border-radius: 6px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #19063d !important;
}
.wpforms-container .wpforms-form .wpforms-field .choices .choices__inner .choices__list--multiple .choices__item .choices__button {
  background-image: url("../img/icon-cross.svg") !important;
  background-size: 10px !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  width: 12px !important;
  height: 12px !important;
}
.wpforms-container .wpforms-form .wpforms-field.wpform-sectoon-divider {
  border-top: 1px solid #e7e7e7 !important;
  margin: 32px 0 !important;
}
.wpforms-container .wpforms-form .wpforms-submit-container {
  text-align: center;
}
.wpforms-container .wpforms-form .wpforms-submit-container .wpforms-submit {
  padding: 16px 28px !important;
  background: #713ddd !important;
  border: 1px solid #713ddd !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  color: #fff !important;
  box-shadow: 0 24px 38px -4px rgba(0,0,0,0.1) !important;
  border-radius: 42px !important;
  position: relative !important;
  width: 100%;
  max-width: 480px;
}
.wpforms-container .wpforms-form .wpforms-submit-container .wpforms-submit:after {
  content: "";
  width: 40px;
  height: 38px;
  background: url("../img/btn-orange-arrow.svg") center no-repeat;
  top: 14px;
  right: 28px;
  position: absolute;
  display: inline-block;
  background-size: contain;
}
.wpforms-container.contact-us .wpforms-form input[type=text],
.wpforms-container.contact-us .wpforms-form input[type=email],
.wpforms-container.contact-us .wpforms-form input[type=tel],
.wpforms-container.contact-us .wpforms-form select,
.wpforms-container.contact-us .wpforms-form textarea {
  background: #f9f5ff !important;
}
.wpforms-container.contact-us .wpforms-form textarea {
  height: 88px !important;
}
.rtl .wpforms-container .wpforms-form .wpforms-submit-container .wpforms-submit:after {
  left: 28px;
  right: auto;
  transform: rotateY(180deg);
}
