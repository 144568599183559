//wpforms
.wpforms-container
  .wpforms-form
    .wpforms-field
      padding: 0;
      padding-bottom: 10px;
      .wpforms-field-description,
      .wpforms-field-limit-text
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #A4A3A8;
      input[type=text],
      input[type=email],
      input[type=tel],
      select,
      textarea
        padding: 10px!important;
        height: 44px!important;
        font-style: normal!important;
        font-weight: 400!important;
        font-size: 14px!important;
        line-height: 24px!important;
        color: #19063D!important;
        background: #FBFAF6!important;
        border-radius: 8px!important;
        box-shadow: none!important;
        border: none!important;
      .wpforms-field-date-time-date-month,
      .wpforms-field-date-time-date-day
        width: 25%
      .wpforms-field-date-time-date-year
        width: 40%
      .wpforms-field-date-time-date-sep
        width: 5%
        color: #fff;
      .wpforms-field-label
        font-style: normal!important;
        font-weight: 500!important;
        font-size: 12px!important;
        line-height: 20px!important;
        color: #19063D!important;
      .wpforms-required-label
        color: #19063D!important;
      .choices
        .choices__inner
          min-height: 44px;
          border-radius: 8px;
          background: #FBFAF6;
          border: none;
          .choices__list--multiple
            .choices__item
              background: #FFFFFF!important;
              border: 1px solid #F5F1E1!important;
              border-radius: 6px!important;
              font-style: normal!important;
              font-weight: 500!important;
              font-size: 12px!important;
              line-height: 20px!important;
              color: #19063D!important;
              .choices__button
                background-image: url(../img/icon-cross.svg)!important;
                background-size: 10px!important;
                background-position: center center!important;
                background-repeat: no-repeat!important;
                width: 12px!important;
                height: 12px!important;
      &.wpform-sectoon-divider
        border-top: 1px solid #E7E7E7!important;
        margin: 32px 0!important;
    .wpforms-submit-container
      text-align: center;
      .wpforms-submit
        padding: 16px 28px!important;
        background: #713ddd!important;
        border: 1px solid #713ddd!important;
        font-weight: 500!important;
        font-size: 18px!important;
        line-height: 28px!important;
        color: #fff!important;
        box-shadow: 0 24px 38px -4px rgba(0,0,0,.1)!important;
        border-radius: 42px!important;
        position: relative!important;
        width: 100%;
        max-width: 480px;
        &:after
          content: "";
          width: 40px;
          height: 38px;
          background: url(../img/btn-orange-arrow.svg) center no-repeat;
          top: 14px;
          right: 28px;
          position: absolute;
          display: inline-block;
          background-size: contain;
  &.contact-us
    .wpforms-form
      input[type=text],
      input[type=email],
      input[type=tel],
      select,
      textarea
        background: #F9F5FF!important;
      textarea
        height: 88px!important;
.rtl
  .wpforms-container
    .wpforms-form
      .wpforms-submit-container
        .wpforms-submit
          &:after
            left: 28px;
            right: auto;
            transform: rotateY(180deg);