.modal
  width: 100%
  max-width 1440px
  max-height 100vh
  top: 50%!important
  left: 50%!important
  transform translate(-50%,-50%)!important
  margin: 0!important
  box-shadow: 60px 76px 150px rgba(52, 64, 84, 0.12);
  border-radius: 20px;
  @media only screen and (max-width: 1460px)
    max-width: 100%
  .modal-cross
    display: flex
    justify-content center
    align-items center
    position: absolute
    top: 20px
    right: 20px
    width: 24px
    height: 24px
    z-index: 10
    &--m
      display: none
    @media only screen and (max-width: 760px)
      &--m
        display: flex
      &--d
        display: none
  .modal-content
    padding: 0

  .modal-sections-wrap
    display: flex
    @media only screen and (max-width: 760px)
      flex-wrap wrap
  .modal-section
    padding: 38px 32px
    @media only screen and (max-width: 992px)
      padding-left: 24px
      padding-right: 24px
    @media only screen and (max-width: 760px)
      padding: 32px 16px
    &__logo-wrap
      margin-bottom: 32px
    &--form
      width: 640px
      background: #fff
      @media only screen and (max-width: 1200px)
        width: 50%
      @media only screen and (max-width: 992px)
        width: 384px
      @media only screen and (max-width: 760px)
        width: 100%
    &--banner
      width: calc(100% - 640px)
      background: radial-gradient(96.49% 87.45% at 97.43% 5.26%, #3B2957 19.47%, #1A0643 100%)
      position: relative
      padding: 114px 96px 168px
      @media only screen and (max-width: 1200px)
        padding-left: 48px
        padding-right: 48px
        width: 50%
      @media only screen and (max-width: 992px)
        width: calc(100% - 384px)
        padding-left: 24px
        padding-right: 24px
      @media only screen and (max-width: 760px)
        padding-top: 68px
        width: 100%
  .brand-logo
    display: inline-flex

  .modal-section-form

    &__title
      margin-top: 48px
      margin-bottom: 16px
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #19063D;
      @media only screen and (max-width: 992px)
        font-size: 18px;
        line-height: 24px;
      @media only screen and (max-width: 760px)
        font-size: 24px;
        line-height: 32px;

    &__bootom-text
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #A4A3A8;
      text-align: center
      margin-top: 32px
      a
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #713DDD;
        text-decoration: none

    &__copyright
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #A4A3A8;
      margin-top: 24px

  .modal-section-banner

    &__bg
      position: absolute
      top: 50%
      left: 50%
      transform translate(-50%,-50%)
      width: 100%
      height: 100%
      object-fit cover

    &__content
      position: relative
      z-index: 2

    &__star-wrap
      width: 72px
      height: 72px
      display: flex
      justify-content center
      align-items center
      margin-bottom: 32px
      @media only screen and (max-width: 992px)
        width: 54px
        height: 54px
        margin-bottom: 24px
      img
        max-width 100%

    &__title
      font-weight: 800;
      font-size: 72px;
      line-height: 90px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      margin-bottom: 24px
      @media only screen and (max-width: 992px)
        font-size: 54px;
        line-height: 68px;
        margin-bottom: 18px

    &__text
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #EAECF0;
      @media only screen and (max-width: 992px)
        font-size: 14px;
        line-height: 22px;

    &__avatars-block
      display: flex
      flex-wrap wrap
      align-items center
      margin-top: 32px
      @media only screen and (max-width: 992px)
        margin-top: 24px

    &__avatars-img-wrap
      margin-right: 16px
      margin-bottom: 16px
      width: 174px
      @media only screen and (max-width: 992px)
        width: 112px
      img
        max-width 100%

    &__avatars-text
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #EAECF0;
      margin-bottom: 16px
      @media only screen and (max-width: 992px)
        font-size: 12px;
        line-height: 18px;

    &__arrow
      position: absolute
      pointer-events none
      bottom: 48px
      left: -48px
      @media only screen and (max-width: 992px)
        display: none

