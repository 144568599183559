.s-header
  position: relative
  z-index: 2
  padding-top: 100px
  height: 800px
  @media only screen and (max-width: 1100px)
    height: 458px
    padding-top: 52px
  @media only screen and (max-width: 600px)
    height auto
    padding-bottom: 380px


  &__bg-img
    position: absolute
    left: 50%
    top: 50%
    transform translate(-50%,-50%)

    &--t,
    &--m
      display: none

    @media only screen and (max-width: 1100px)
      &--t
        display: block
        margin-left: 74px
      &--d,
      &--m
        display: none
    @media only screen and (max-width: 600px)
      bottom: 0
      transform translateX(-50%)
      top: auto
      &--m
        display: block
      &--d,
      &--t
        display: none

  &__content
    position: relative
    z-index: 2

  &__text-wrap
    max-width 576px
    position: relative
    @media only screen and (max-width: 1100px)
      max-width: 360px;
    @media only screen and (max-width: 600px)
      max-width inherit

  &__arrow-right
    position: absolute
    top: 20px
    right: -20px
    @media only screen and (max-width: 1100px)
      position: absolute;
      top: -20px;
      right: -90px;
    @media only screen and (max-width: 600px)
      display: none

  &__arrow-left
    position: absolute
    bottom: -100px
    right: 100px
    @media only screen and (max-width: 1100px)
      bottom: -70px;
      right: -44px;
    @media only screen and (max-width: 600px)
      display: none

  &__arrow-heart
    position: absolute
    top: 110px
    right: 40px
    @media only screen and (max-width: 1100px)
      top: 80px;
      right: -60px;
    @media only screen and (max-width: 600px)
      right: 10px
      height: 46px

  &__ptetitle
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #19063D;
    margin-bottom: 20px
    @media only screen and (max-width: 1100px)
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 16px

  &__title
    font-weight: 800;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -0.02em;
    color: #19063D;
    margin-bottom: 24px
    @media only screen and (max-width: 1100px)
      font-size: 54px;
      line-height: 68px;
      letter-spacing: -0.02em;
      margin-bottom: 18px
    @media only screen and (max-width: 600px)
      font-size: 44px;
      line-height: 44px;
      position: relative
      z-index: 3

  &__text
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #514468;
    @media only screen and (max-width: 1100px)
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;

  &__btn-wrap
    display: flex
    align-items center
    margin-top: 48px
    @media only screen and (max-width: 1100px)
      margin-top: 36px
    @media only screen and (max-width: 600px)
      flex-wrap wrap

  &__started-btn
    display: flex
    align-items center
    padding: 16px 28px
    background: #713DDD;
    border: 1px solid #713DDD;
    box-shadow: 0px 24px 38px -4px rgba(0, 0, 0, 0.16);
    border-radius: 42px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    @media only screen and (max-width: 1100px)
      padding: 12px 20px
      font-size: 14px;
      line-height: 21px;
    @media only screen and (max-width: 600px)
      margin-right: 20px
      margin-bottom: 20px
    .more-wrap
      margin-left: 12px
      width: 26px
      height: 26px
      border-radius 50%
      display: flex
      justify-content center
      align-items center
      background: #FF8057;
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
      @media only screen and (max-width: 1100px)
        width: 20px
        height: 20px

  &__work-btn
    display: flex
    align-items center
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #19063D;
    margin-left: 28px
    @media only screen and (max-width: 1100px)
      margin-left: 20px
      font-size: 12px;
      line-height: 18px;
    @media only screen and (max-width: 600px)
      margin-left: 0
      margin-bottom: 20px

    .play-wrap
      display: flex
      justify-content center
      align-items center
      width: 60px
      height: 60px
      border-radius 50%
      background: #FFFFFF;
      box-shadow: 0px 16px 38px -4px rgba(0, 0, 0, 0.16);
      margin-right: 16px
      @media only screen and (max-width: 1100px)
        width: 45px
        height: 45px
        margin-right: 12px


  &.s-header-about
    @media only screen and (max-width: 600px)
      padding-bottom: 64px
    .s-header__title
      font-weight: 800;
      font-size: 48px;
      line-height: 60px;
      letter-spacing: -0.02em;
      @media only screen and (max-width: 1100px)
        font-size: 36px;
        line-height: 45px;
      @media only screen and (max-width: 600px)
        font-size: 44px;
        line-height: 56px;
    .s-header__bg-img
      left: 0
      transform translateY(-50%)
      margin-left: 0!important
      &--t
        left: -164px
      &--m
        position: static
        margin-bottom: 0
        max-width 100%
        transform none
        margin-left: auto!important
        margin-right: auto!important
    .s-header__text-wrap
      margin-left: auto
    .s-header__blocks-wrap
      display: flex
      width: calc(100% + 32px)
      margin-left: -16px
      margin-top: 48px
      flex-wrap wrap
      @media only screen and (max-width: 1100px)
        margin-top: 32px
      @media only screen and (max-width: 600px)
        margin-top: 16px
    .s-header__block
      width: calc(100%/2 - 32px)
      margin: 16px
      @media only screen and (max-width: 600px)
        width: calc(100%/1 - 32px)
    .s-header__block-title
      font-weight: 600;
      font-size: 60px;
      line-height: 72px;
      letter-spacing: -0.02em;
      color: #713DDD;
      margin-bottom: 12px
      @media only screen and (max-width: 1100px)
        font-size: 45px;
        line-height: 54px;
        margin-bottom: 8px
      @media only screen and (max-width: 600px)
        font-size: 60px;
        line-height: 72px;
        margin-bottom: 12px
    .s-header__block-text
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #19063D;
      @media only screen and (max-width: 1100px)
        font-size: 14px;
        line-height: 21px;
      @media only screen and (max-width: 600px)
        font-size: 18px;
        line-height: 28px;

  &.s-header-redeem
    .container
      position: relative
    .s-header__bg-img
      left: auto
      right 0
      transform translateY(-50%)
      @media only screen and (max-width: 1100px)
        width: 600px
        right: -150px
      @media only screen and (max-width: 600px)
        width: 100%
        max-width 100%
        position: static
        transform none
    .s-header__title
      max-width 420px
      @media only screen and (max-width: 1100px)
        max-width 320px
      @media only screen and (max-width: 600px)
        max-width inherit
    .s-header__text-wrap
      margin-left: 0
      margin-right: auto
    .s-header__block-title
      font-size: 80px;
      line-height: 72px;
      @media only screen and (max-width: 1100px)
        font-size: 60px;
        line-height: 54px;
      @media only screen and (max-width: 600px)
        font-size: 60px;
        line-height: 54px;
    .s-header__block
      position: relative
      width: 130px
      @media only screen and (max-width: 1100px)
        width: 98px
      .s-header__block-text
        @media only screen and (max-width: 1100px)
          font-size: 14px;
          line-height: 21px;
      .s-header__block-icon
        position: absolute
        padding: 8px
        width: 45px
        height: 45px
        border-radius 50%
        background: #FFFFFF;
        box-shadow: 0px 12px 28.5px -3px rgba(0, 0, 0, 0.16);
        right: -62px
        top: 12px
        display: flex
        justify-content center
        align-items center
        @media only screen and (max-width: 1100px)
          width: 34px
          height: 34px
          right: -44px
          top: 8px
        img
          max-width 100%
          max-height 100%


