.s-footer
  position: relative
  background: radial-gradient(89.24% 304.82% at 92.36% 150.75%, #3B2957 20.19%, #1A0643 100%)
  padding-top: 64px
  padding-bottom: 48px
  @media only screen and (max-width: 992px)
    padding-top: 52px
    padding-bottom: 36px
  @media only screen and (max-width: 767px)
    padding-top: 64px
    padding-bottom: 48px
  &__bottom
    padding-top: 32px
    border-top 1px solid #554671
    display: flex
    justify-content space-between
  &__copyright
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    padding-right: 24px
    @media only screen and (max-width: 992px)
      font-size: 12px;
      line-height: 18px;
    @media only screen and (max-width: 767px)
      font-size: 14px;
      line-height: 24px;
  &__socilals-wrap
    display: flex
    align-items center
    gap 24px
    @media only screen and (max-width: 992px)
      gap 18px
  &__socilal
    @media only screen and (max-width: 992px)
      width: 18px
      img
        max-width 100%
    @media only screen and (max-width: 767px)
      width: auto
  &__content
    display: flex
    align-items flex-start
    padding-bottom: 58px
    @media only screen and (max-width: 992px)
      flex-wrap wrap
  &__logo-wrap
    width: 340px
    @media only screen and (max-width: 1100px)
      width: 300px
    @media only screen and (max-width: 992px)
      width: 100%
  &__logo
    margin-bottom: 28px
    @media only screen and (max-width: 992px)
      margin-bottom: 20px
    @media only screen and (max-width: 767px)
      margin-bottom: 28px
  &__logo-text
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #EAECF0;
    @media only screen and (max-width: 992px)
      font-size: 12px;
      line-height: 18px;
    @media only screen and (max-width: 767px)
      font-size: 16px;
      line-height: 24px;
  &__links-wrap
    width: calc(100% - 340px + 32px)
    display: flex
    align-items flex-start
    margin-left: -16px
    @media only screen and (max-width: 1100px)
      width: calc(100% - 300px + 32px)
    @media only screen and (max-width: 992px)
      width: calc(100% + 32px)
      margin-top: 48px
    @media only screen and (max-width: 767px)
      flex-wrap wrap
      margin-top: 0
  &__link-col
    width: calc(100%/5 - 32px)
    margin-left: 16px
    margin-right: 16px
    @media only screen and (max-width: 767px)
      width: calc(100%/2 - 32px)
      margin-top: 48px
  &__link-col-title
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 10px
    @media only screen and (max-width: 992px)
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 7px
    @media only screen and (max-width: 767px)
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px
  &__link-wrap
    display: flex
  &__link
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 0.7;
    transition all .3s ease
    margin-top: 6px
    margin-bottom: 6px
    @media only screen and (max-width: 992px)
      margin-top: 5px
      padding-bottom: 5px
      font-size: 12px;
      line-height: 18px;
    @media only screen and (max-width: 767px)
      font-size: 14px;
      line-height: 24px;
    &:hover
      opacity 1

.s-footer
  &__links-wrap
    @media only screen and (max-width: 992px)
      text-align: left;
    @media only screen and (max-width: 767px)
      flex-wrap: wrap;
    > li.menu-item
      width: calc(100%/5);
      margin-left: 16px;
      margin-right: 16px;
      text-align: left;
      @media only screen and (max-width: 767px)
        width: calc(100% / 2 - 32px);
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 48px;
      a
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        margin-bottom: 10px;
      ul
        margin: 0;
        li.menu-item
          margin-top: 7px;
          margin-bottom: 7px;
          a
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #fff;
            opacity: .7;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
            &:hover
              opacity: 1;

.site-primary-footer-inner-wrap
  padding-top: 32px;
  border-top: 1px solid #554671;
  @media only screen and (max-width: 921px)
    padding-top: 24px;
  @media only screen and (max-width: 767px)
    padding-top: 32px;

.site-above-footer-inner-wrap
  display: flex;
  @media only screen and (max-width: 921px)
    flex-wrap: wrap;

.site-footer-above-section-1
  width: 320px;
  margin-right: 48px;
  @media only screen and (max-width: 921px)
    width: 100%;
    margin: 0

.site-footer-above-section-2
  width: calc(100% - 320px - 48px);
  @media only screen and (max-width: 921px)
    width: 100%;