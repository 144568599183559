


/*
p -- page
s -- section
b -- block
pr -- block preview (pr-xxs, pr-xs, pr-s, pr-m, pr) if different sizes on site
f -- form
m -- menu
l -- list
i -- img links, lone links, logos, svg
t -- tables
title -- default titles
btn -- buttons
msg -- message - not content

_error*
_success*
_active*
*/

$grayHeader= #20233F;
$gray= #4D4D4D;
$green= #3EBB78;
$greenHover = #1DAC5F;
$greenActive = #1D8C50;
$white= #FFFFFF;
$azure= #5EC4C6;
$red = #EE4865;

*
*:before
*:after
  -webkit-box-sizing border-box
  -moz-box-sizing border-box
  box-sizing border-box
.owl-item
  transform translateZ(0)   //фиксим изчезновения слайдов

img
  display block
button
  background transparent
input,textarea
  font-family: 'Alexandria', sans-serif;
  font-weight 400
  -webkit-appearance none //убираем тени и прочую дрянь на мобилах
  border-radius: 0 //убираем скругления - для мобил
  color $gray
html,body
  color $gray
  height 100%
  font-size 16px
  line-height: 28px
  font-weight 400
  font-family: 'Alexandria', sans-serif;
  -webkit-font-smoothing antialiased   //сглаживаем шрифты
  -moz-osx-font-smoothing grayscale   //сглаживаем шрифты
  -webkit-overflow-scrolling touch  //производительный скролл
  -webkit-tap-highlight-color: rgba(0,0,0,0); //убираем выделение при нажатии


//footer to bottom
* {
  margin: 0
  padding: 0
}
header
footer
section {
  max-width 100vw
}
html,
body {
  width: 100%
  height: 100%
}
body {
  max-width 100vw
  overflow-x: hidden;
}
.b-wrapper
  width: 100%
  display: table
  height: 100%
  padding-top: 120px
  max-width 100vw
  overflow-x hidden
  @media only screen and (max-width: 976px)
    padding-top: 97px
  @media only screen and (max-width: 767px)
    padding-top: 72px

.content {
  display: table-row
  width: 100%
  height: 100%
}

span
  font-size inherit
  line-height: inherit
  color inherit
  font-weight: inherit

.section {
  width: 100%
}
.hidden
  display: none!important

.container
  max-width 1272px
  padding-left: 15px
  padding-right: 15px
  width: 100%

.row
  margin-bottom 0

.col
  padding 0 15px
  @media only screen and (max-width: 600px)
    padding-left: 0!important
    padding-right: 0!important

.dropdown-content
  top: 40px!important
  background: #FFFFFF;
  box-shadow: 0px 32px 38px -4px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  min-width 164px
  padding: 8px!important
  li
    min-height inherit
    background transparent!important
  li>a
    padding: 6px 16px
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #19063D;
    border-radius: 8px;
    transition all .3s ease
    &:hover
      background: #F9F5FF;


.btn

  &--purple
    background: #713DDD;
    border: 1px solid #713DDD;
    box-shadow: 0px 16px 38px -4px rgba(0, 0, 0, 0.16);
    border-radius: 42px;
    padding: 10px 18px
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

.bg-text
  color #fff
  position: relative
  display: inline-flex
  margin-left: 8px
  &:before
    content ''
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background url("../img/text-bg.png") center no-repeat
    background-size cover
    z-index: -1

.collapsible
  border none
  box-shadow none
  li
    padding-top: 24px
    padding-bottom: 36px
    border-bottom 1px solid #EAECF0
    &:last-child
      border-bottom none
    &.active
      .collapsible-header
        .collapsible-header__plus
          &:before
            display: none!important
    .collapsible-body,
    .collapsible-header
      border none
      position: relative
    .collapsible-body
      padding: 8px 46px 0 0
      @media only screen and (max-width: 600px)
        padding: 8px 30px 0 0
      &__text
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #514468;
    .collapsible-header
      padding: 0 46px 0 0
      @media only screen and (max-width: 600px)
        padding: 0 30px 0 0
      &__title
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #19063D;
      &__plus
        position: absolute
        width: 20px
        height: 20px
        border: 2px solid #FF8057;
        border-radius 50%
        right: 0
        top: 50%
        transform translateY(-50%)
        &:after
          content ''
          display: block
          position: absolute
          height: 2px
          width: 10px
          top: 50%
          left: 50%
          transform translate(-50%,-50%)
          background #FF8057
        &:before
          content ''
          display: block
          position: absolute
          width: 2px
          height: 10px
          top: 50%
          left: 50%
          transform translate(-50%,-50%)
          background #FF8057




