.s-our-story
  padding-top: 96px
  padding-bottom: 96px
  background: radial-gradient(89.24% 304.82% at 92.36% 150.75%, #3B2957 20.19%, #1A0643 100%)
  position: relative
  @media only screen and (max-width: 1240px)
    padding-top: 72px
    padding-bottom: 72px
  @media only screen and (max-width: 720px)
    padding-top: 96px
    padding-bottom: 96px
  &__bg-img
    position: absolute
    width: 100%
    height: 100%
    top: 50%
    left: 50%
    transform translate(-50%,-50%)
    object-fit cover
  &__content
    position: relative
    z-index: 2
  &__top-img
    margin-bottom: 24px
    width: 72px
    @media only screen and (max-width: 1240px)
      margin-bottom: 18px
      width: 54px
    @media only screen and (max-width: 720px)
      margin-bottom: 24px
      width: 72px
  &__blocks-wrap
    width: calc(100% + 64px)
    margin-left: -32px
    display: flex
    align-items flex-start
    flex-wrap wrap
  &__block
    width: calc(100%/3 - 64px)
    margin: 48px 32px 32px
    @media only screen and (max-width: 1240px)
      width: calc(100%/2 - 64px)
    @media only screen and (max-width: 720px)
      width: calc(100%/1 - 64px)
      margin: 64px 32px 0
    &--main
      margin-top: 0
      @media only screen and (max-width: 1240px)
        width: calc(100%/1 - 64px)
        margin-bottom: 0
  &__pretitle
    font-size: 14px;
    line-height: 24px;
    color: #CBBFE3;
    margin-bottom: 12px
    @media only screen and (max-width: 1240px)
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 10px
    @media only screen and (max-width: 720px)
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 12px
  &__title
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-bottom: 20px
    @media only screen and (max-width: 1240px)
      font-size: 27px;
      line-height: 33px;
      margin-bottom: 16px
    @media only screen and (max-width: 720px)
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 20px
    &--small
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      margin-bottom: 8px
      @media only screen and (max-width: 1240px)
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 6px
      @media only screen and (max-width: 720px)
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 8px
  &__description
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #CBBFE3;
    @media only screen and (max-width: 1240px)
      font-size: 15px;
      line-height: 22px;
    @media only screen and (max-width: 720px)
      font-size: 20px;
      line-height: 30px;
  &__text
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #CBBFE3;
    margin-bottom: 20px
    &:last-child
      margin-bottom: 0
    @media only screen and (max-width: 1240px)
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 16px
    @media only screen and (max-width: 720px)
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px
