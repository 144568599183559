.s-team
  padding-top: 96px
  padding-bottom: 96px
  position: relative
  @media only screen and (max-width: 1240px)
    padding-top: 48px
    padding-bottom: 48px
  @media only screen and (max-width: 720px)
    padding-top: 96px
    padding-bottom: 96px

  &__pretitle
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #19063D;
    margin-bottom: 12px
    @media only screen and (max-width: 1240px)
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 8px
    @media only screen and (max-width: 720px)
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 12px

  &__title
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #19063D;
    margin-bottom: 20px
    @media only screen and (max-width: 1240px)
      font-size: 36px;
      line-height: 45px;
      margin-bottom: 16px
    @media only screen and (max-width: 720px)
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 20px

  &__description
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #514468;
    max-width 768px
    margin-left: auto
    margin-right: auto
    @media only screen and (max-width: 1240px)
      font-size: 16px;
      line-height: 22px;
    @media only screen and (max-width: 720px)
      font-size: 20px;
      line-height: 30px;

  &__blocks-wrap
    display: flex
    width: calc(100% + 32px)
    margin-left: -16px
    margin-top: 48px
    flex-wrap wrap
    @media only screen and (max-width: 1240px)
      width: calc(100% + 24px)
      margin-left: -12px
      margin-top: 32px
    @media only screen and (max-width: 720px)
      width: calc(100% + 32px)
      margin-left: -16px
      margin-top: 48px

  &__block
    width: calc(100%/3 - 32px)
    margin: 16px
    @media only screen and (max-width: 1240px)
      width: calc(100%/3 - 24px)
      margin: 12px
    @media only screen and (max-width: 720px)
      width: calc(100%/1 - 32px)
      margin: 24px 16px

  &__block-photo-wrap
    border-radius: 24px;
    overflow hidden
    margin-bottom: 24px
    width: 100%
    max-height: 296px
    @media only screen and (max-width: 1240px)
      margin-bottom: 18px
      border-radius: 18px;
    @media only screen and (max-width: 720px)
      margin-bottom: 24px
      border-radius: 24px;
    img
      max-width 100%
      width: 100%

  &__block-name
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #19063D;
    margin-bottom: 16px
    @media only screen and (max-width: 1240px)
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 12px
    @media only screen and (max-width: 720px)
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 16px

  &__block-position
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #514468;
    @media only screen and (max-width: 1240px)
      font-size: 12px;
      line-height: 18px;
    @media only screen and (max-width: 720px)
      font-size: 16px;
      line-height: 24px;

  &__block-socials-wrap
    display: flex
    flex-wrap wrap
    margin-top: 6px
    @media only screen and (max-width: 1240px)
      margin-top: 2px
    @media only screen and (max-width: 720px)
      margin-top: 6px

  &__block-social
    margin-top: 20px
    margin-right: 20px
    width: 24px
    img
      max-width 100%
    @media only screen and (max-width: 1240px)
      margin-top: 16px
      margin-right: 16px
      width: 18px
    @media only screen and (max-width: 720px)
      margin-top: 20px
      margin-right: 20px
      width: 24px



