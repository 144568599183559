.s-feature
  padding-top: 96px
  padding-bottom: 96px
  @media only screen and (max-width: 1100px)
    padding-top: 52px
    padding-bottom: 52px

  &__subtitle
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #19063D;
    margin-bottom: 12px
    @media only screen and (max-width: 1100px)
      font-size: 12px;
      line-height: 18px;

  &__title
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #19063D;
    margin-bottom: 64px
    @media only screen and (max-width: 1100px)
      margin-bottom: 32px
      font-size: 27px;
      line-height: 33px;
    @media only screen and (max-width: 767px)
      margin-bottom: 0


  &__blocks-wrap
    display: flex
    align-items flex-start
    @media only screen and (max-width: 767px)
      flex-wrap wrap

  &__block
    width: 50%
    @media only screen and (max-width: 767px)
      width: 100%
      &--left
        display: flex
        justify-content center

  &__block-img
    max-width 100%

  &__block-item
    margin-bottom: 48px
    display: flex
    align-items flex-start
    transform translateX(100%)
    opacity 0
    transition all .3s ease
    &:last-child
      margin-bottom: 0
    @media only screen and (max-width: 1100px)
      margin-bottom: 36px

  &__block-item-icon
    width: 48px
    height: 48px
    @media only screen and (max-width: 1100px)
      width: 36px
      height: 36px

  &__block-item-content
    width: calc(100% - 48px)
    padding-left: 12px

  &__block-item-title
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #19063D;
    margin-bottom: 8px
    margin-top: 8px
    @media only screen and (max-width: 1100px)
      font-size: 15px;
      line-height: 22px;


  &__block-item-text
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #514468;
    @media only screen and (max-width: 1100px)
      font-size: 12px;
      line-height: 18px;

  &__block-item-link
    display: inline-flex
    align-items center
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #713DDD;
    margin-top: 20px
    img
      margin: 0 12px
    @media only screen and (max-width: 1100px)
      margin-top: 16px
      font-size: 12px;
      line-height: 18px;



  .s-feature__block-item
    &:nth-child(1)
      transition-delay: .3s;
    &:nth-child(2)
      transition-delay: .6s;
    &:nth-child(3)
      transition-delay: .9s;
    &:nth-child(4)
      transition-delay: 1.2s;
    &:nth-child(5)
      transition-delay: 1.5s;

  &.seen
    .s-feature__block-item
      transform none
      opacity 1


