.page-template-blog
  .s-posts
    padding-top: 0
    padding-bottom: 96px
    overflow hidden
    //margin-top: 200px
    position: relative
    z-index: 3
    @media only screen and (max-width: 1100px)
      padding-bottom: 52px
      margin-top: 0

    .container
      position: relative

    &__tabs-wrap
      display: flex
      justify-content center
      margin-bottom: 64px;
      //@media only screen and (max-width: 767px)
      //  max-width: 48px;


    &__tabs
      display: inline-flex
      justify-content center
      width: auto
      padding: 6px!important
      background: #F9F5FF;
      border-radius: 48px;
      height: auto
      gap: 8px;
      margin-left: 0
      @media only screen and (max-width: 600px)
        margin-left auto
        width:100%
        flex-wrap wrap
        border-radius: 24px;

      .tab
        height: auto
        line-height: inherit
        @media only screen and (max-width: 600px)
          width:100%

        a
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #514468!important;
          padding: 10px 20px
          border-radius: 48px;
          background transparent!important
          text-transform: none
          @media only screen and (max-width: 600px)
            border-radius: 36px;
          &.active
            background: #713DDD!important;
            box-shadow: 0px 24px 38px -4px rgba(0, 0, 0, 0.16);
            color #fff!important

      .indicator
        display: none!important


    &__tab-result-top-wrap
      display: flex
      align-items flex-start
      flex-wrap wrap
      width: calc(100% + 64px)
      margin-left: -32px
      margin-bottom: 80px
      @media only screen and (max-width: 1100px)
        width: calc(100% + 48px)
        margin-left: -24px
        margin-bottom: 60px
      @media only screen and (max-width: 600px)
        margin-bottom: 24px

    &__tab-result-top-block
      width: calc(100%/2 - 64px)
      margin-left: 32px
      margin-right: 32px
      margin-bottom: 32px
      @media only screen and (max-width: 1100px)
        width: calc(100%/2 - 48px)
        margin-left: 24px
        margin-right: 24px
      @media only screen and (max-width: 600px)
        width: calc(100%/1 - 48px)

    &__tab-result-title
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: #19063D;
      @media only screen and (max-width: 1100px)
        font-size: 27px;
        line-height: 33px;

    &__tab-result-description
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #514468;
      @media only screen and (max-width: 1100px)
        font-size: 15px;
        line-height: 22px;

    &__tab-result-blocks-wrap
      display: flex
      flex-wrap wrap
      width: calc(100% + 32px)
      margin-left: -16px
      @media only screen and (max-width: 1100px)
        width: calc(100% + 24px)
        margin-left: -12px

    &__tab-result-block
      width: calc(100%/3 - 32px)
      margin: 16px
      //background: #F9FAFB;
      //border-radius: 20px;
      overflow hidden
      @media only screen and (max-width: 1100px)
        width: calc(100%/2 - 24px)
        margin: 12px
      @media only screen and (max-width: 600px)
        width: calc(100%/1 - 24px)

    &__tab-result-block-img-wrap
      position: relative
      //box-shadow: 0px 32px 38px -4px rgba(0, 0, 0, 0.07);
      border-radius: 20px;
      overflow hidden
      height: 240px
      @media only screen and (max-width: 976px)
        height: 180px
      img
        position: absolute
        top: 50%
        left: 50%
        transform translate(-50%,-50%)
        object-fit cover
        width: 100%
        height: 100%

    //&__tab-result-block-counter
    //  position: absolute
    //  top: 16px
    //  left: 24px
    //  padding: 10px 10px 10px 46px
    //  background: #F9F5FF;
    //  border-radius: 32px;
    //  z-index: 2
    //  @media only screen and (max-width: 1100px)
    //    left: 20px
    //
    //&__tab-result-block-counter-icon
    //  background: #FFFFFF;
    //  box-shadow: 0px 9.6px 22.8px -2.4px rgba(0, 0, 0, 0.16);
    //  width: 36px
    //  height: 36px
    //  border-radius 50%
    //  position: absolute
    //  left: 2px
    //  top: 2px
    //  padding: 7px
    //  img
    //    max-width 100%
    //    max-height 100%
    //    width: auto
    //    height: auto
    //
    //&__tab-result-block-counter-number
    //  font-weight: 700;
    //  font-size: 16px;
    //  line-height: 20px;
    //  color: #19063D;

    &__tab-result-block-text-wrap
      padding: 24px 24px 30px 24px
      @media only screen and (max-width: 1100px)
        padding: 18px 18px 22px 18px

    &__tab-result-block-pretitle
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #CBBFE3;
      margin-bottom: 12px
      @media only screen and (max-width: 1100px)
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 8px

    &__tab-result-block-title
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #19063D;
      @media only screen and (max-width: 1100px)
        font-size: 18px;
        line-height: 24px;

    &__tab-result-coming-wrap
      height: 680px
      width: 100%
      position: relative
      display: flex
      align-items center
      @media only screen and (max-width: 1100px)
        height: 400px
      @media only screen and (max-width: 740px)
        height: auto
        flex-wrap wrap

    &__tab-result-coming-text-wrap
      max-width 540px
      margin-left: 16px
      @media only screen and (max-width: 1100px)
        max-width 334px
      @media only screen and (max-width: 740px)
        max-width inherit
        width: 100%
        margin-bottom: 40px

    &__tab-result-coming-title
      font-weight: 800;
      font-size: 48px;
      line-height: 60px;
      letter-spacing: -0.02em;
      color: #19063D;
      margin-bottom: 24px
      @media only screen and (max-width: 1100px)
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 18px

    &__tab-result-coming-text
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #514468;
      @media only screen and (max-width: 1100px)
        font-size: 15px;
        line-height: 22px;

  //.pagination
  //  margin-top: 48px
  //  display: flex
  //  justify-content center
  //  padding-left: 90px
  //  padding-right: 90px
  //  position: relative
  //  padding-bottom: 0
  //  flex-wrap wrap
  //  padding-top: 20px
  //  border-top: 1px solid #EAECF0;
  //  @media only screen and (max-width: 740px)
  //    padding-top: 0
  //    border-top 0
  //    padding-left: 50px
  //    padding-right: 50px
  //
  //  .current.prev,
  //  .page-link.prev
  //    position: absolute
  //    font-weight: 500;
  //    font-size: 14px;
  //    line-height: 20px;
  //    color: #514468;
  //    padding-left: 24px
  //    border none
  //    background none
  //    box-shadow none
  //    top: 20px
  //    left: 0
  //    width: auto!important
  //    &:before
  //      content ''
  //      position: absolute
  //      width: 12px
  //      height: 12px
  //      display: flex
  //      justify-content center
  //      align-items center
  //      background url("../img/s-arrow-prev.svg") center no-repeat
  //      background-size cover
  //      left: 0
  //      top: 50%
  //      transform translateY(-50%)
  //
  //  .current.next,
  //  .page-link.next
  //    position: absolute
  //    font-weight: 500;
  //    font-size: 14px;
  //    line-height: 20px;
  //    color: #514468;
  //    padding-right: 24px
  //    border none
  //    background none
  //    box-shadow none
  //    top: 20px
  //    right: 0
  //    width: auto!important
  //    &:before
  //      content ''
  //      position: absolute
  //      width: 12px
  //      height: 12px
  //      display: flex
  //      justify-content center
  //      align-items center
  //      background url("../img/s-arrow-next.svg") center no-repeat
  //      background-size cover
  //      right: 0
  //      top: 50%
  //      transform translateY(-50%)
  //
  //  .ellipse,
  //  .page-link,
  //   li span
  //    width: 40px
  //    height: 40px
  //    margin: 0
  //    border none
  //    background none
  //    box-shadow none
  //    font-weight: 500;
  //    font-size: 14px;
  //    line-height: 20px;
  //    text-align: center;
  //    color: #19063D;
  //    display: flex
  //    justify-content center
  //    align-items center
  //
  //  .current
  //    width: 40px
  //    height: 40px
  //    margin: 0
  //    border none
  //    background: #F9FAFB;
  //    border-radius: 8px;
  //    box-shadow none
  //    font-weight: 500;
  //    font-size: 14px;
  //    line-height: 20px;
  //    text-align: center;
  //    color: #19063D;
  //    display: flex
  //    justify-content center
  //    align-items center
  //  @media only screen and (max-width: 740px)
  //    .current.prev,
  //    .page-link.prev,
  //    .current.next,
  //    .page-link.next,
  //    .current
  //      display: flex!important
  //      width: auto
  //      background none
  //    .current
  //      &:before
  //        position: relative
  //        content 'Page'
  //        display: block
  //        left: 0
  //        margin-right: 6px
  //    .current.prev,
  //    .page-link.prev,
  //    .current.next,
  //    .page-link.next
  //      font-size 0
  //      line-height: 0
  //      width: 36px;
  //      height: 36px;
  //      background: #FFFFFF;
  //      border: 1px solid #D0D5DD;
  //      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  //      border-radius: 8px;
  //      top: 50%
  //      transform translateY(-50%)
  //      &:before
  //        top: 50%
  //        left: 50%
  //        transform translate(-50%,-50%)
  //
  //    .ellipse,
  //    .page-link
  //      display: none
  //      &:nth-last-child(2)
  //        display: flex!important
  //        width: auto
  //        padding-left: 0
  //        &:before
  //          position: relative
  //          content 'of'
  //          display: block
  //          left: 0
  //          margin-right: 6px