.s-widget

  &__content
    max-width 85vw
    margin-left: auto
    padding: 38px 56px
    background: #FFFFFF;
    box-shadow: 4px 32px 38px rgba(0, 0, 0, 0.04);
    display: flex
    align-items center
    border-radius: 20px;
    @media only screen and (max-width: 1240px)
      flex-wrap wrap
      max-width inherit
      border-radius 0
      padding: 28px 24px

  &__title
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #713DDD;
    max-width 304px
    @media only screen and (max-width: 1240px)
      font-size: 22px;
      line-height: 30px;
    @media only screen and (max-width: 720px)
      margin-bottom: 48px

  &__logos-wrap
    display: flex
    align-items center
    width: calc(100% - 304px)
    justify-content space-around
    padding-left: 32px
    @media only screen and (max-width: 1240px)
      padding-left: 0
      width: calc(100% + 54px)
      margin-left: -27px
      justify-content flex-start
    @media only screen and (max-width: 720px)
      flex-wrap wrap
      width: 100%
      margin-left: 0

  &__logo-wrap
    @media only screen and (max-width: 720px)
      width: 100%
      margin-bottom: 54px
      display: flex
      justify-content center
      &:last-child
        margin-bottom: 0

  &__logo
    @media only screen and (max-width: 1240px)
      margin: 27px
    @media only screen and (max-width: 720px)
      margin: 0


  &.s-widget-about
    background: #F9F5FF;
    .s-widget__content
      background transparent
      box-shadow none
      @media only screen and (max-width: 1480px)
        max-width inherit
    .s-widget__logo
      mix-blend-mode: darken;
    .s-widget__title
      color: #19063D;
