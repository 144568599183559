.s-open-position
  padding-top: 96px
  padding-bottom: 96px
  position: relative
  @media only screen and (max-width: 1240px)
    padding-top: 72px
    padding-bottom: 72px
  @media only screen and (max-width: 720px)
    padding-top: 46px
    padding-bottom: 96px

  &__pretitle
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #19063D;
    margin-bottom: 12px
    @media only screen and (max-width: 1240px)
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 8px
    @media only screen and (max-width: 720px)
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 12px

  &__title
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #19063D;
    margin-bottom: 20px
    @media only screen and (max-width: 1240px)
      font-size: 36px;
      line-height: 45px;
      margin-bottom: 16px
    @media only screen and (max-width: 720px)
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 20px

  &__description
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #514468;
    max-width 768px
    margin-left: auto
    margin-right: auto
    @media only screen and (max-width: 1240px)
      font-size: 16px;
      line-height: 22px;
    @media only screen and (max-width: 720px)
      font-size: 20px;
      line-height: 30px;

  &__img-wrap
    margin-top: 64px
    border-radius: 24px;
    overflow: hidden
    display: block
    img
      max-width 100%
      width: 100%
    @media only screen and (max-width: 976px)
      margin-top: 48px
      border-radius: 18px;
    @media only screen and (max-width: 767px)
      height: 400px
      margin-top: 64px
      border-radius: 24px;
      position: relative
      img
        position: absolute
        height: 100%
        width: auto
        max-width inherit
        top: 50%
        left: 50%
        transform translate(-50%,-50%)

