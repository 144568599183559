.author,
.single-post
  .post-featured-image
    img
      width: 100%;
      object-fit:contain;
  .post-conclusion
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 32px 40px!important;
    gap: 24px;
    @media only screen and (max-width: 976px)
      padding: 24px 24px 30px!important;
      gap: 18px;
    @media only screen and (max-width: 767px)
      padding: 24px 20px 32px!important;
      gap: 24px;
  .post-inner
    max-width: 748px;
    margin: 0 auto;
    padding: 14px;
    border-bottom 1px solid #EAECF0
    @media only screen and (max-width: 767px)
      padding: 6px;
  .entry-meta
    .updated
      display: none;

.page-template-blog,
.author,
.post-template-default
  .page-descr
    max-width 720px
    margin-left: auto
    margin-right: auto
  .b-wrapper
    padding-top: 216px
    @media only screen and (max-width: 976px)
      padding-top: 169px
    @media only screen and (max-width: 767px)
      padding-top: 136px
  .entry-meta
    text-align: center
    margin-bottom: 12px
    @media only screen and (max-width: 976px)
      margin-bottom: 8px
    @media only screen and (max-width: 767px)
      margin-bottom: 12px
    span,
    a
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #CBBFE3;
      text-decoration: none
      @media only screen and (max-width: 976px)
        font-size: 12px;
        line-height: 18px;
      @media only screen and (max-width: 767px)
        font-size: 14px;
        line-height: 20px;
    .author-name
      position: relative
      padding-right: 20px
      &:after
        content ''
        display: block
        position: absolute
        top: 50%
        right 6px
        width: 4px
        height: 4px
        border-radius 50%
        background-color: #CBBFE3;
        transform translateY(-50%)
  h1
    margin-top: 0
    margin-bottom: 24px
    font-weight: 600;
    font-size: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #19063D;
    max-width 960px
    margin-left: auto
    margin-right: auto
    @media only screen and (max-width: 976px)
      margin-bottom: 18px
      font-size: 36px;
      line-height: 45px;
    @media only screen and (max-width: 767px)
      margin-bottom: 16px
      font-size: 36px;
      line-height: 44px;
  h2
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #19063D;
    margin-bottom: 24px
    margin-top: 48px
    @media only screen and (max-width: 976px)
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 18px
      margin-top: 36px
    @media only screen and (max-width: 767px)
      margin-bottom: 20px
      font-size: 24px;
      line-height: 32px;
      margin-top: 48px
  h3
    margin-top: 0
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #19063D;
    margin-bottom: 24px
    @media only screen and (max-width: 976px)
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 18px
    @media only screen and (max-width: 767px)
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px
  ul,
  ol,
  p
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #514468;
    margin-bottom: 24px
    @media only screen and (max-width: 976px)
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 18px
    @media only screen and (max-width: 767px)
      margin-bottom: 20px
      font-size: 18px;
      line-height: 28px;
  .post-bottom
    display: flex
    align-items center
    flex-wrap wrap
    justify-content space-between
    max-width 720px
    margin-left: auto
    margin-right: auto
    padding-top: 24px
    padding-bottom: 24px
    margin-bottom: 60px
  .post-author
    display: flex
    align-items center
    @media only screen and (max-width: 767px)
      margin-bottom: 26px
      width: 100%
    p
      margin: 0
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #514468;
    .author-name
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #19063D;
    .gravatar
      margin-right: 16px
      width: 56px;
      height: 56px;
      border-radius 50%
      overflow hidden
      img
        max-width 100%
        max-height 100%
  .post-conclusion
    gap: 0!important
    margin-bottom: 48px!important
    @media only screen and (max-width: 976px)
      margin-bottom: 36px!important
    @media only screen and (max-width: 767px)
      margin-bottom: 48px!important
  .wp-block-image
    margin-bottom: 48px
    margin-top: 48px
    @media only screen and (max-width: 976px)
      margin-bottom: 36px
      margin-top: 36px
    @media only screen and (max-width: 767px)
      margin-bottom: 48px
      margin-top: 48px
    img
      border-radius: 20px;
      width: auto
      height: auto
      max-width 100%
  .wp-element-caption
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #514468;
    margin-bottom: 0
    margin-top: 16px
    @media only screen and (max-width: 976px)
      margin-top: 12px
    a
      font-size inherit
      line-height: inherit
      font-weight: inherit
      text-decoration: underline
      color inherit
  .wp-block-quote
    position: relative
    padding-left: 22px
    padding-top: 4px
    padding-bottom: 4px
    margin-bottom: 36px
    @media only screen and (max-width: 976px)
      padding-left: 17px
    &:before
      content ''
      display: block
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 2px
      background-color: #713DDD
    p
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 32px
      color: #19063D;
      @media only screen and (max-width: 976px)
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 24px
      @media only screen and (max-width: 767px)
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 32px
    cite
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #514468;
      font-style normal
      @media only screen and (max-width: 976px)
        font-size: 12px;
        line-height: 18px;
      @media only screen and (max-width: 767px)
        font-size: 16px;
        line-height: 24px;
  .s-posts__tab-result-block-tags,
  .post-tags
    margin-bottom: 60px
    display: flex
    flex-wrap wrap
    justify-content center
    @media only screen and (max-width: 976px)
      margin-bottom: 44px
    @media only screen and (max-width: 767px)
      margin-bottom: 32px
    span,
    a
      margin: 4px
      padding: 2px 10px
      background: #F9F5FF;
      border-radius: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #713DDD;
  .post-featured-image
    margin-bottom: 96px
    overflow hidden
    @media only screen and (max-width: 976px)
      margin-bottom: 72px
    @media only screen and (max-width: 767px)
      margin-bottom: 64px
    img
      border-radius: 20px;
      width: auto
      height: auto
      max-width 100%
  .post-share__socials
    background-color: transparent
    display: flex
    align-items center
    margin-bottom: 0
    li
      margin: 6px
      a
        padding: 10px
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        display: flex
        align-items center
        justify-content center
        img
          width: 20px
          height: 20px
    .copy-link
      padding: 10px 16px 10px 44px
      position: relative
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #514468;
      background: #FFFFFF;
      border: 1px solid #D0D5DD;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      &:before
        content ''
        display: block
        position: absolute
        top: 50%
        left: 17px
        transform translateY(-50%)
        width: 16px
        height: 16px
        background: url(../img/copy.svg) center no-repeat
        background-size cover
  .post-excerpt
    padding-bottom: 32px
    margin-bottom: 0
    border-bottom 1px solid #EAECF0;
    max-width 720px
    margin-left auto
    margin-right auto
    @media only screen and (max-width: 976px)
      padding-bottom: 24px
    @media only screen and (max-width: 767px)
      padding-bottom: 32px
      padding-left: 6px
      padding-right: 6px
    p
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #514468;
      @media only screen and (max-width: 976px)
        font-size: 15px;
        line-height: 22px;
      @media only screen and (max-width: 767px)
        font-size: 18px;
        line-height: 28px;


.page-template-blog
  .s-posts__tab-result-block-text-wrap
    position: relative
    max-width 608px
    &:after
      content ''
      display: block
      width: 10px
      height: 10px
      position: absolute
      top: 70px
      right: 0
      background: url(../img/post-arrow.svg) center no-repeat
      background-size cover
  .s-posts__tab-result-block-tags
    justify-content flex-start
  .entry-meta
    text-align: left
  .s-posts__tab-result-block-title
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #19063D;
    margin-bottom: 12px
    @media only screen and (max-width: 976px)
      font-size: 22px;
      line-height: 28px;
  .s-posts__tab-result-block-excerpt
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #514468;
    margin-bottom: 18px
    @media only screen and (max-width: 976px)
      font-size: 12px;
      line-height: 18px;


  .s-posts__tab-result-block-text-wrap
    padding-left: 0!important
    padding-right: 0!important


.page-template-blog
  .s-latest-post
    .s-posts__tab-result-block-title
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #19063D;
      margin-bottom: 12px
      @media only screen and (max-width: 976px)
        font-size: 22px;
        line-height: 28px;
    .s-posts__tab-result-block-excerpt
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #514468;
      margin-bottom: 18px
      @media only screen and (max-width: 976px)
        font-size: 12px;
        line-height: 18px;
