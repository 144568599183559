.top-menu
  height: auto
  line-height: inherit;
  background-color: transparent
  position: fixed
  top: 0
  left: 0
  width: 100%
  padding: 38px 112px
  display: flex
  align-items center
  justify-content space-between
  border none
  box-shadow none
  z-index: 100
  @media only screen and (max-width: 1400px)
    padding: 28px 34px
  @media only screen and (max-width: 600px)
    padding: 16px 20px
  &.not-top
    background-color: $white
  .brand-logo
    position: static
    transform none!important
  .anqors-wrap,
  .nav-wrapper
    display: flex
    align-items center
  .anqors-wrap
    margin-left: 56px
    margin-bottom: 0
    .menu-item
      a
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #19063D;
        transition: all .3s ease
        padding: 0
        margin: 0 16px
        background transparent
        &:hover
          color: #713DDD;
      &.current-menu-item
        a
          color: #713DDD;
      &.menu-item-has-children
        padding-right: 23px
        position: relative
        &.active
          background: none
        &:after
          content ''
          display: block
          width: 10px
          height: 5px
          position: absolute
          right: 14px
          top: 50%
          transform translateY(-50%)
          background url("../img/dropdown.svg") center no-repeat
          background-size cover
      .sub-menu
        position: absolute
        top: 40px!important
        background: #FFFFFF;
        box-shadow: 0px 32px 38px -4px rgba(0, 0, 0, 0.07);
        border-radius: 20px;
        min-width 164px
        padding: 8px!important
        display: none
        li
          min-height inherit
          background transparent!important
          width: 100%
          &.current-menu-item
            a
              background: #F9F5FF;
        li>a
          padding: 6px 16px
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #19063D;
          border-radius: 8px;
          transition all .3s ease
          margin: 0
          &:hover
            background: #F9F5FF;

.auth-wrapper
  display: flex
  align-items center
  .dropdown-content
    top: 90px!important
  .top-menu__login
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #713DDD;
    padding: 10px 18px
    margin: 0 8px
  .top-menu__signup
    margin: 0 8px
  .dropdown-content--lang
    top: 60px!important
    a
      padding: 10px 12px
      background: transparent;
      border-radius: 8px;
      display: flex
      align-items center
      margin-left: 8px
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #514468;
      &:hover
        background: #F9F5FF;
      .top-menu__lang-flag,
      img
        width: 24px
        height: 24px
        border-radius 50%
        overflow hidden
        margin-right: 8px
  .top-menu__lang
    padding: 10px 12px
    background: #F9F5FF;
    border-radius: 8px;
    display: flex
    align-items center
    margin-left: 8px
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #514468;
    .top-menu__lang-flag
      width: 24px
      height: 24px
      border-radius 50%
      overflow hidden
      margin-right: 8px
    .top-menu__lang-caret
      margin-left: 12px

.desctop-menu
  @media only screen and (max-width: 992px)
    display: none

.mobile-menu
  display: none
  @media only screen and (max-width: 992px)
    display: block
  .nav-wrapper
    width: 100%
    justify-content space-between
  .sidenav-trigger
    height: auto
    margin: 0

.sidenav
  background-color: $white
  width: 380px
  z-index: 10000
  padding: 20px!important
  @media only screen and (max-width: 600px)
    width: 100%
  .sidenav-header
    display: flex
    justify-content flex-end
    align-items center
    margin-bottom: 30px
    .sidenav-cross
      width: 24px
      height: 24px
      display: flex
      justify-content center
      align-items center
      img
        height: 14px
        width: 14px
  .sidenav-content
    padding-top: 16px
    padding-bottom: 16px
    .top-menu__login
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #713DDD;
      padding: 10px 18px
    .top-menu__link-wrap
      margin: 24px 0
      display: flex
      &--btns
        margin: 12px 0
    .top-menu__link
      font-weight: 800;
      font-size: 27px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: #19063D;
    .top-menu__collapse
      .menu-item
        margin: 24px 0;
        a
          font-weight: 800;
          font-size: 27px;
          line-height: 32px;
          height: inherit;
          letter-spacing: -0.02em;
          color: #19063D;
          padding: 0;
          width: fit-content;
        &.current-menu-item
          a
            color: #713DDD;
        &.menu-item-has-children
          padding-right: 23px
          position: relative
          transition: all .3s ease
          &:after
            content ''
            display: block
            width: 16px
            height: 10px
            position: absolute
            right: 14px
            top: 12px
            transform: scaleY(-1);
            background url("../img/panel-dropdown-arrow.svg") center no-repeat
            background-size cover
          &.active-menu
            a
              color: #713DDD;
              background: none;
            &:after
              transform: scaleY(1);
          .sub-menu
            display: none
            li
              width: 100%
              margin: 12px 0;
            li>a
              font-weight: 500;
              font-size: 20px;
              line-height: 32px;
              color: #19063D;
        &.active
          background transparent!important
          .collapsible-header
            .collapsible-header__title
              color: #713DDD!important;
            .collapsible-header__arrow-wrap
              transform rotate(0)
        .collapsible-header
          padding: 0
          display: flex
          align-items center
          background transparent!important
          margin-bottom: 12px
          .collapsible-header__arrow-wrap
            transform rotate(180deg)
            transition all .3s ease
          .collapsible-header__title
            font-weight: 800;
            font-size: 27px;
            line-height: 32px;
            letter-spacing: -0.02em;
            color: #19063D;
            margin-right: 16px
        .collapsible-body
          padding: 0
          .collapsible-body__link-wrap
            margin: 12px 0
          .collapsible-body__link
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -0.02em;
            color: #19063D;
            &:hover
              color #713DDD

.rtl
  .top-menu
    .anqors-wrap
      .menu-item
        &.menu-item-has-children
          padding-left: 23px
          padding-right: inherit
          &:after
            content: none;
          &:before
            content ''
            display: block
            width: 10px
            height: 5px
            position: absolute
            left: 14px
            top: 50%
            transform translateY(-50%)
            background url("../img/dropdown.svg") center no-repeat
            background-size cover
  .sidenav
    .sidenav-content
      .top-menu__collapse
        .menu-item
          &.menu-item-has-children
            padding-left: 23px
            padding-right: inherit
            &:after
              right: auto;
              left 14px
 .auth-wrapper
  .top-menu__lang
    .top-menu__lang-caret
      margin-right: 12px;
      margin-left: inherit;
