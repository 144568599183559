.s-grades-tabs
  padding-top: 44px
  padding-bottom: 44px

  &__pretitle
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #19063D;
    margin-bottom: 12px

  &__title
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #19063D;
    margin-bottom: 38px
    max-width 768px
    margin-left: auto
    margin-right: auto

  &__tabs-wrap
    display: flex
    justify-content center

  &__tabs
    display: inline-flex
    justify-content center
    width: auto
    padding: 6px!important
    background: #F9F5FF;
    border-radius: 48px;
    height: auto
    gap: 8px;
    @media only screen and (max-width: 600px)
      width:100%
      flex-wrap wrap
      border-radius: 24px;

    .tab
      height: auto
      line-height: inherit
      @media only screen and (max-width: 600px)
        width:100%

      a
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #514468!important;
        padding: 10px 20px
        border-radius: 48px;
        background transparent!important
        text-transform: none
        @media only screen and (max-width: 600px)
          border-radius: 36px;
        &.active
          background: #713DDD!important;
          box-shadow: 0px 24px 38px -4px rgba(0, 0, 0, 0.16);
          color #fff!important

    .indicator
      display: none!important

  &__blocks-wrap
    display: flex
    width: calc(100% + 32px)
    margin-left: -16px
    align-items flex-start
    @media only screen and (max-width: 1200px)
      flex-wrap wrap

  &__block
    margin-top: 144px
    margin-left: 16px
    margin-right: 16px
    width: calc(100%/3 - 32px)
    position: relative
    background: #F9FAFB;
    border-radius: 20px;
    padding: 80px 24px 32px
    @media only screen and (max-width: 1200px)
      margin-top: 80px
      width: calc(100%/1 - 32px)
    @media only screen and (max-width: 600px)
      padding-left: 16px
      padding-right: 16px

  &__block-top-img
    position: absolute
    right: -76px;
    top: -116px;
    pointer-events none

  &__block-content
    position: relative
    z-index: 2

  &__block-number-wrap
    display: flex
    align-items flex-end
    margin-bottom: 8px

  &__block-number
    font-weight: 700;
    font-size: 120px;
    line-height: 140px;
    letter-spacing: -0.02em;
    color: #CBBFE3;
    font-family: 'Oswald', sans-serif;

  &__block-number-text
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #CBBFE3;
    margin-left: 8px
    margin-bottom: 8px

  &__block-title
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #19063D;

  &__block-table
    margin-top: 24px
    margin-bottom: 32px
    padding: 16px 6px
    background: #FFFFFF;
    box-shadow: 0px 32px 38px -4px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    display: flex

  &__block-table-col
    width: calc(100%/3)
    position: relative
    padding: 4px 10px
    &:after
      content ''
      display: block
      position: absolute
      right: 0
      top: 0
      height: 100%
      width: 1px
      background #EAECF0;
    &:last-child
      &:after
        display: none

  &__block-table-number
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #19063D;
    margin-bottom: 4px

  &__block-table-text
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #514468;

  &__block-list
    position: relative
    padding-bottom: 60px
    @media only screen and (max-width: 1200px)
      display: flex
      flex-wrap wrap
      width: calc(100% + 16px)
      margin-left: -8px

  &__block-list-img
    position: absolute
    bottom: -56px;
    right: -18px;
    pointer-events none
    @media only screen and (max-width: 600px)
      right: -32px

  &__block-list-item
    margin-bottom: 16px
    position: relative
    padding-left: 36px
    z-index: 2
    &:before
      content ''
      display: block
      width: 24px
      height: 24px
      position: absolute
      top: 0
      left: 0
      background url("../img/check.svg") center no-repeat
      background-size cover
    &:last-child
      max-width 178px
    @media only screen and (max-width: 1200px)
      width: calc(100%/3 - 16px)
      margin-left: 8
      margin-right: 8px
    @media only screen and (max-width: 767px)
      width: calc(100%/1 - 16px)

  &__block-list-item-title
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px
    color: #19063D;

  &__block-list-item-text
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #514468;

  &__block-btns-wrap
    display: flex
    flex-wrap wrap
    position: relative
    z-index: 2
    @media only screen and (max-width: 540px)
      justify-content center

  &__block-btn--demo
    background: #FFFFFF;
    box-shadow: 0px 16px 38px -4px rgba(0, 0, 0, 0.16);
    border-radius: 48px;
    padding: 12px 20px
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    margin-right: 12px
    margin-bottom: 12px
    text-align: center
    @media only screen and (max-width: 1200px)
      width: calc(100%/2 - 6px)
    @media only screen and (max-width: 767px)
      width: auto
    @media only screen and (max-width: 540px)
      width: 100%
      margin-right: 0

  &__block-btn--started
    background: #713DDD;
    border: 1px solid #713DDD;
    box-shadow: 0px 24px 38px -4px rgba(0, 0, 0, 0.16);
    border-radius: 42px;
    padding: 10px 35px
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 12px
    text-align: center
    @media only screen and (max-width: 1200px)
      width: calc(100%/2 - 6px)
    @media only screen and (max-width: 767px)
      width: auto
    @media only screen and (max-width: 540px)
      width: 100%



.rtl
  .s-grades-tabs
    &__block-list-item
      padding-left: auto
      padding-right: 36px
      &:before
        left: auto
        right: 0
    &__block-list-img
      right: auto;
      left: -18px;
      @media only screen and (max-width: 600px)
        left: -32px
        right: auto
    &__block-top-img
      left: -76px;
      right: auto;

    &__block-table-col
      width: calc(100%/3)
      position: relative
      padding: 4px 10px
      &:before
        content ''
        display: block
        position: absolute
        right: 0
        top: 0
        height: 100%
        width: 1px
        background #EAECF0;
      &:first-child
        &:before
          display: none
      &:after
        display: none