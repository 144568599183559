.s-reviews
  padding-top: 96px
  padding-bottom: 96px
  overflow hidden
  @media only screen and (max-width: 1100px)
    padding-top: 52px
    padding-bottom: 52px

  &__title
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.01em;
    color: #19063D;
    margin-bottom: 68px
    @media only screen and (max-width: 1100px)
      font-size: 27px;
      line-height: 33px;
      margin-bottom: 50px

  &__slider
    padding-bottom: 88px
    position: relative

  .slick-list
    overflow visible
    &:before
      content ''
      display: block
      position: absolute
      top: 0
      left: -4000px
      width: 4000px
      height: 200%
      background-color: #fff
      z-index: 2

  .slick-arrow
    position: absolute
    bottom: 0
    width: 56px
    height: 56px
    border: 1px solid #EAECF0;
    border-radius 50%
    display: flex
    justify-content center
    align-items center

  .prev-btn
    left: 0

  .next-btn
    left: 88px

  &__slide
    width: 360px;
    margin-right: 32px
    border-radius: 24px;
    padding: 24px
    &--blue
      background: #EAF8FF;
    &--pink
      background: #FBEFF7;
    &--orange
      background: #FFF6ED;
    @media only screen and (max-width: 1100px)
      margin-right: 24px
      padding: 18px
      width: 270px

  &__slide-content
    padding: 16px 20px
    background: #FFFFFF;
    box-shadow: 4px 32px 38px -4px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    @media only screen and (max-width: 1100px)
      padding: 12px 16px

  &__slide-head
    display: flex;
    margin-bottom: 20px;
    img
      width: 60px;
      height: 60px;
      border-radius: 300px;
      margin-right: 12px;
    .info
      .slide-title
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        //margin-bottom: 16px
        color: #19063D;
        @media only screen and (max-width: 1100px)
          font-size: 15px;
          line-height: 22px;
          //margin-bottom: 12px
      .slide-school-name
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #514468;
        opacity: 0.5;
        //@media only screen and (max-width: 1100px)
        //  font-size: 15px;
        //  line-height: 22px;
        //  margin-bottom: 12px

      &__slide-text
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #514468;
        @media only screen and (max-width: 1100px)
          font-size: 14px;
          line-height: 22px;



.rtl
  .s-reviews
    .slick-list
      &:before
        left: auto
        right: -4070px
    &__slide-head
      img
        margin-left: 12px;
        margin-right: 0;