.s-faq
  padding-bottom: 60px
  @media only screen and (max-width: 1100px)
    padding-top: 56px
    padding-bottom: 32px
  &__content
    max-width 766px
    margin-left: auto
    margin-right: auto
  &__title
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #19063D;
    margin-bottom: 20px
    @media only screen and (max-width: 1100px)
      font-size: 27px;
      line-height: 33px;
      margin-bottom: 16px
  &__description
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #514468;
    @media only screen and (max-width: 1100px)
      font-size: 15px;
      line-height: 22px;
  &__collapse
    margin-top: 40px
    @media only screen and (max-width: 1100px)
      margin-top: 20px
