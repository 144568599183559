//lesson
.single-curriculum
  .s-header-block
    .pre-title
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
      @media only screen and (max-width: 976px)
        font-size: 10.5px;
        line-height: 18px;
        margin-bottom: 12px;
    h1
      font-weight: 800;
      font-size: 72px!important;
      line-height: 90px;
      margin-bottom: 58px;
      margin-top: 0
      @media only screen and (max-width: 976px)
        font-size: 54px;
        line-height: 68px;
        margin-bottom: 43px;
      @media only screen and (max-width: 767px)
        margin-bottom: 24px;
      strong
          font-weight: 800;
          font-size: 72px!important;
          color: #713DDD!important;
          @media only screen and (max-width: 976px)
            font-size: 54px!important;
    .after-title
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #CBBFE3;
      margin-bottom: 8px
      @media only screen and (max-width: 976px)
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 12px
      strong
        font-weight: 700;
        font-size: 120px;
        line-height: 140px;
        @media only screen and (max-width: 976px)
          font-size: 90px;
          line-height: 105px;
    .label
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
      @media only screen and (max-width: 976px)
        font-size: 27px;
        line-height: 33px;
    .info-block
      box-shadow: 0px 24px 28.5px -3px rgba(0, 0, 0, 0.07);
      border-radius: 15px;
      h4
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin: 0
        margin-bottom: 4px;
      p
        font-size: 16px;
        line-height: 20px;
      .border-right
        border-right: 1px solid #EAECF0;

.s-description-block
  .wp-block-uagb-icon-list
    .uagb-icon-list__label
      font-size: 18px;
      line-height: 28px;